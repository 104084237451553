import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../services/auth.service';

export const authGuard = async (route: any, state: any): Promise<boolean> => {
  const toastr = inject(ToastrService); // Inietta ToastrService
  const router = inject(Router); // Inietta Router
  const authService = inject(AuthService); // Inietta AuthService

  const currentUser = authService.currentUserValue;

  if (currentUser && currentUser.jwtToken) {
    // Utente autenticato, consenti l'accesso
    return true;
  }

  // Utente non autenticato, mostra messaggio e reindirizza alla home con query params
  toastr.warning("Login scaduto, effettua l'accesso", "Attenzione");
  await router.navigate(['/'], {queryParams: {returnUrl: state.url}});
  return false;
};
