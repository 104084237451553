import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ProductType } from '@enums/productType.enum';
import { IAuthenticateResponse } from '@models/authenticate.model';
import { MyProduct } from '@models/myProduct.model';
import { AuthService } from '@services/auth.service';
import { ProductService } from '@services/products.service';
import { environment } from '@envs/environment';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe-decorator";
import { Subscription } from "rxjs";
import { TypeCovers } from '@/helpers/enums/typeCovers.enum';

@Component({
  selector: 'app-my-products-list',
  templateUrl: './my-products-list.component.html',
  styleUrls: ['./my-products-list.component.scss']
})
export class MyProductsListComponent implements OnInit {

  serverUrl: string = environment.storageUrl;
  products: MyProduct[] = null;
  availableTypes: ProductType[] = [];
  productType = ProductType;
  currentUser!: IAuthenticateResponse;
  typeCovers = TypeCovers;

  loading = false;
  searchNote: string = ''

  productCount: number = 0;

  defaultImage: string = "https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/tt_logo.png"

  @AutoUnsubscribe()
  productSubscription: Subscription

  constructor(
    private titleService: Title,
    private authService: AuthService,
    private productService: ProductService) {
    this.currentUser = this.authService.currentUserValue
    this.titleService.setTitle(`I Miei Prodotti | Top-Trading Academy`)
  }

  async ngOnInit() {
    this.productSubscription = this.productService.myProducts.subscribe(async val => {
      if (val && val.length) {
        this.products = val
        await this.getAvailableTypes(this.products)
        this.loading = false
      } else {
        //this.loading = true
        await this.productService.searchMyProductsMinimal(this.searchNote, 0, 0, 0)
          .catch(() => {
            this.products = []
          })
          .finally(() => {
            this.loading = false
          })
      }
    })
  }

  async getAvailableTypes(products: MyProduct[]) {
    this.availableTypes = products
      .filter((thing, i, arr) => arr.findIndex(t => t.productType === thing.productType) === i)
      .map(x => x.productType)
  }
}
