import {PlatformType} from '@/helpers/enums/platformType.indicator';
import {ProductType} from '@/helpers/enums/productType.enum';
import {TypeCovers} from '@/helpers/enums/typeCovers.enum';
import {Product} from '@/helpers/models/product.model';
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-product-cover',
  templateUrl: './product-cover.component.html',
  styleUrls: ['./product-cover.component.scss']
})
export class ProductCoverComponent implements OnInit {

  @Input() products: Product[];
  @Input() type: ProductType;
  @Input() platform: PlatformType;

  platformType = PlatformType;
  productType = ProductType;
  typeCovers = TypeCovers;

  availableTypeCovers: TypeCovers [] = [];

  constructor() {
  }

  ngOnInit(): void {
    if (this.products.some(x => x.typeCovers === this.typeCovers.CoverFree && x.productType === this.type && x.platformType === this.platform)) {
      this.availableTypeCovers.push(this.typeCovers.CoverFree)
    }
    if (this.products.some(x => x.typeCovers === this.typeCovers.Cover && x.productType === this.type && x.platformType === this.platform)) {
      this.availableTypeCovers.push(this.typeCovers.Cover)
    }
  }

}
