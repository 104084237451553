import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fade } from '../../animations/fade.animation';
import { IAuthenticateRequest, IAuthenticateResponse } from '../../models/authenticate.model';
import { AuthService } from '../../services/auth.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { EmailPattern } from "../../patterns/validators.patterns";
import { ProductService } from "@/helpers/services/products.service";
import { DiscordLoginResponse } from "@/helpers/modals/discordLoginResponse.model";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-login-modal',
  animations: [fade],
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent {

  @Input() loginShow: boolean = false;
  @Output() modalCallback: EventEmitter<any> = new EventEmitter()
  @Output() confirmModal: EventEmitter<any> = new EventEmitter()

  registerShow: boolean = false;
  loading: boolean = false;
  loadingDiscord: boolean = false;
  succeeded: boolean = false;
  returnUrl: string = ''

  request: IAuthenticateRequest = {
    email: '',
    password: ''
  }

  public form: UntypedFormGroup

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private authenticationService: AuthService,
    private toastr: ToastrService) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
    this.form = this.formBuilder.group({
      email: new UntypedFormControl(this.request.email, [Validators.required, Validators.pattern(EmailPattern)]),
      password: new UntypedFormControl(this.request.password, [Validators.required])
    })
  }

  get m() {
    return this.form.controls
  }

  toggleModal = (event: any) => this.modalCallback.emit(this.loginShow)

  toggleRegisterModal = (event: any) => this.registerShow = !this.registerShow

  showRegisterModal() {
    this.modalCallback.emit(this.loginShow)
    this.registerShow = !this.registerShow
  }

  onSubmit() {
    if (this.form.valid) {
      this.loading = true;
      this.authenticationService.login(this.request)
        .subscribe(async (_user: IAuthenticateResponse) => {
            if (_user && _user.jwtToken) {
              // controlla se l'utente deve completare l'account
              if (_user.fiscalCode == undefined) {
                setTimeout(() => {
                  this.loading = false;
                  this.succeeded = true;
                }, 200)
                setTimeout(() => {
                  this.modalCallback.emit(this.loginShow)
                  this.confirmModal.emit()
                }, 1500)
                this.authenticationService.shouldConfirmAccount.next(true)
              } else {
                // in caso contrario continua con il login normale
                this.loading = false;
                this.succeeded = true;
                this.modalCallback.emit(this.loginShow)
                this.productService.myProducts.next([])
                this.productService.myProductDetails.next([])
                localStorage.removeItem('tta-invite')
                localStorage.removeItem('tta-playerToken')
                localStorage.removeItem('tta-lead')
                localStorage.removeItem('tta-currentPlayer')
                localStorage.removeItem('tta-isPlayerPlaying')
                localStorage.removeItem('tta-currentorderid')
                this.toastr.success('Ci fa piacere riaverti tra noi!', `Ciao ${_user.firstName}!`)
                await this.router.navigate([this.returnUrl])
              }
            }
          },
          (err: any) => {
            this.loading = false
            this.toastr.warning(err.error, 'Login fallito')
          })
    } else {
      this.toastr.warning('Email e password sono obbligatori', 'Attenzione')
    }
  }

  loginWithDiscord() {
    this.loadingDiscord = true;
    this.authenticationService.loginWithDiscord()
      .then((res: DiscordLoginResponse) => {
        window.location.href = res.url
      })
      .catch(() => {
        this.loadingDiscord = false
      })
  }
}
