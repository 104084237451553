import { Component, OnInit, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductType } from '@enums/productType.enum';
import { IAuthenticateResponse } from '@models/authenticate.model';
import { MyProduct } from '@models/myProduct.model';
import { AuthService } from '@services/auth.service';
import { DiscordService } from '@services/discord.service';
import { OrderDetailService } from '@services/orderDetails.service';
import { PlayerService } from '@services/players.service';
import { ProductService } from '@services/products.service';
import { StripeTTAService } from '@services/stripeTTA.service';
import { environment } from '@envs/environment'
import { SEOService } from '@services/seo.service';
import { Subject, Subscription } from 'rxjs';
import { ProductEnvironment } from '@enums/productEnvironment.enum';
import { Location } from '@angular/common';
import { LastSeenCourse, Pagination } from '@models/lastSeenVideos.model';
import { fade } from "@animations/fade.animation";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe-decorator";
import { SubscriptionService } from "@services/subscription.service";
import { Product } from "@models/product.model";
import { ProductWithDate } from "@models/productWithDate.model";
import { TypeCovers } from '@/helpers/enums/typeCovers.enum';

let that: any;

@Component({
  selector: 'app-my-product-detail',
  templateUrl: './my-product-detail.component.html',
  styleUrls: ['./my-product-detail.component.scss'],
  animations: [fade]
})
export class MyProductDetailComponent implements OnInit {

  serverUrl: string = environment.storageUrl;
  productId!: number;
  productEnvironment: ProductEnvironment
  currentUser: IAuthenticateResponse;
  loading: boolean = false;
  childLoading: boolean = false
  hasAssistenza: boolean = false;
  isProductSubscribable: boolean = false;
  isAlreadySubscribed: boolean = false
  subscribableProductTypes: ProductType[] = [ProductType.Indicatore, ProductType.Pacchetto_Indicatori, ProductType.Membership]

  @AutoUnsubscribe()
  subscriptionConfirmation: Subject<boolean> = new Subject()
  productType = ProductType;
  myProduct!: MyProduct;
  showProductChildren: MyProduct[] = []
  filteredProductChildren: MyProduct[] = []
  typeCovers = TypeCovers;
  showProductChildrenOptional: MyProduct[] = []
  subscribedProduct: MyProduct[] = []
  filteredProductChildrenOptional: MyProduct[] = []
  assistenzaProduct: MyProduct[] = []
  assistenzaProductOptional: MyProduct[] = []

  @AutoUnsubscribe()
  myProductSubscription: Subscription

  defaultImage: string = "https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/tt_logo.png"
  productSections: string[] = []
  productSectionsOptional: string[] = []

  currentPage: number[] = []
  lastSeenVideos: LastSeenCourse[] = []

  constructor(
    private subscriptionService: SubscriptionService,
    private location: Location,
    private stripeTTAService: StripeTTAService,
    private seo: SEOService,
    private discordService: DiscordService,
    private playerService: PlayerService,
    private router: Router,
    private orderDetailService: OrderDetailService,
    private stripe: StripeTTAService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private productService: ProductService,
    private authService: AuthService
  ) {
    that = this;
    this.route.params.subscribe(params => {
      this.productId = parseInt(params['productId'])
      this.productEnvironment = parseInt(params['productEnvironment'])
      this.seo.updateTitle(`My ${params['productName']
        .split('-')
        .join(' ')
        .toLowerCase()
        .replace(/\b\w/g, s => s.toUpperCase())} | Top-Trading Academy`)
    })
    this.currentUser = this.authService.currentUserValue
  }

  async ngOnInit() {
    this.myProductSubscription = this.productService.myProductDetails.subscribe(async myProducts => {
      if (myProducts.some(x => x.productId === this.productId)) {
        this.myProduct = myProducts.find(x => x.productId === this.productId)
        this.seo.updateDescription(this.myProduct.description)
/*         if (this.myProduct && this.isSubscribable(this.myProduct)) {
          await this.checkSubscription(this.myProduct)
          await this.checkPurchasedDate(this.myProduct)
        } */
        const getLastSeenVideos = await this.getLastSeenVideos()
        const getCurrentPage = await this.getCurrentPage()
        const filterAssistenza = await this.filterAssistenza()
        const filterOpzionali = await this.filterOpzionali()
        await Promise.all([getLastSeenVideos, getCurrentPage, filterAssistenza, filterOpzionali])
          .then(() => {
          })
      } else {
        if (this.productId > 0 && this.productId != undefined) {
          const getMyProduct = await this.getMyProduct()
          const getLastSeenVideos = await this.getLastSeenVideos()
          const getCurrentPage = await this.getCurrentPage()
          await Promise.all([getMyProduct, getLastSeenVideos, getCurrentPage])
            .then(() => {
            })
        }
      }
    })
  }

  /**
   * Carica prodotto da API e lo aggiunge alla lista dei prodotti appartenenti all'utente.
   * Se l'utente è abbonato al prodotto o un suo sottoprodotto (assistenza), carica anche le sezioni
   * di video riservate agli utenti abbonati.
   */
  async getMyProduct() {
    this.loading = true
    return await this.productService.getMyProduct(this.productId, this.productEnvironment, this.isAlreadySubscribed)
      .then(async (_myProduct: MyProduct) => {
        let _myProducts: MyProduct[] = this.productService.myProductDetails.value
        if (_myProducts.some(x => x.productId === _myProduct.productId)) {
          _myProducts = _myProducts.filter(x => x.productId !== _myProduct.productId)
        }
        _myProducts.push(_myProduct)
        this.productService.myProductDetails.next(_myProducts)
        if (this.myProduct.typeCovers !== TypeCovers.CoverFree) {
          await this.orderDetailService.doesOwnProduct(this.currentUser.accountId, this.myProduct.name, that.productId)
          .then(async res => {
            if (res.item1 && res.item2) {
              await this.filterAssistenza()
              await this.filterOpzionali()
            } else {
              this.toastr.warning('Non sei in possesso di questo prodotto', 'Attenzione')
              await this.router.navigateByUrl('/shop')
            }
          })
        }
      }).catch(() => {
        this.toastr.warning("Prodotto non trovato", 'Attenzione')
        this.location.back()
      }).finally(() => {
        this.loading = false;
      })
  }


  async getCategories() {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index
    }

    this.productSections = this.filteredProductChildren
      .map(x => x.productSection)
      .filter(distinct)
  }

  async getCategoriesOptional() {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index
    }

    this.productSectionsOptional = this.filteredProductChildrenOptional
      .map(x => x.productSection)
      .filter(distinct)
  }

  /* x.productType !== ProductType.AssistenzaFree
        && x.productType !== ProductType.Assistenza */
  async filterAssistenza() {
    this.showProductChildren = this.myProduct.childs
      .filter(x => x.productType !== ProductType.Assistenza 
        && x.productType !== ProductType.IntroCorso 
        && x.productType !== this.productType.Pacchetto_Indicatori 
        && x.isOptional == false)

    if (this.myProduct.productType == ProductType.Indicatore && this.myProduct.typeCovers == TypeCovers.Cover) 
      this.showProductChildren.push(this.myProduct)

    if (this.showProductChildren.length > 0)
      this.hasAssistenza = true

    this.assistenzaProduct = this.myProduct.childs.filter(x => x.productType == ProductType.Assistenza && x.isOptional == true)

    this.filteredProductChildren = this.showProductChildren.slice()
    await this.getCategories()
  }

  async filterOpzionali() {
    this.showProductChildrenOptional = this.myProduct.childs
    .filter(x => x.productType !== ProductType.Assistenza 
      && x.isOptional == true)

    this.assistenzaProductOptional = this.myProduct.childs.filter(x => x.productType == ProductType.Assistenza && x.isOptional == true)

    if (this.showProductChildrenOptional.length > 0) {
      this.filteredProductChildrenOptional = this.showProductChildrenOptional.slice()
      await this.getCategoriesOptional()
    }
  }

  /**
   * Controlla se il prodotto è idoneo a un abbonamento.
   * @param product Il prodotto per cui controllare l'abbonamento
   */
/*   isSubscribable(product: MyProduct) {
    this.assistenzaProduct = this.myProduct.childs.find(x => x.productType === ProductType.Assistenza)
    if (this.assistenzaProduct && this.assistenzaProduct.subscriptionCycle !== null) {
      this.isProductSubscribable = true
      return true
    }
    return false
  } */

  /**
   * Controlla se l'utente ha un abbonamento attivo per il prodotto visualizzato.
   * Controllo effettuato solo per le assistenze (no indicatori o membership).
   * In caso l'utente sia abbonato viene mostrato un badge verde nella pagina prodotto,
   * se invece non lo è viene mostrato un bottone tramite il quale può abbonarsi o effettuare
   * il login con Discord (qualora non l'avesse già fatto).
   * Prima di effettuare il controllo lato API il metodo verifica che non sia già stato
   * effettuato tramite il Behavior Subject che contiene i nomi dei prodotti a cui l'utente
   * è abbonato, subject svuotato con refresh o logout.
   * @param product Il prodotto per il quale controllare l'abbonamento
   */
  /* async checkSubscription(product: MyProduct) {
    // se il prodotto non è sottoscrivibile non fare niente (si riferisce al prodotto in sè
    // non ai figli, come nel caso delle assistenze)
    if (this.subscribableProductTypes.includes(product.productType) && !(product.productType === ProductType.Indicatore && product.typeCovers !== TypeCovers.Cover))
      return
    // controlla se l'utente è abbonato al prodotto in questione (la lista subscribedProducts)
    // serve per non effettuare il controllo due o più volte.
    const subscribedProducts: string[] = this.productService.subscribedProducts.value
    // il controllo viene effettuato dal subscriptionService che, se l'utente è abbonato al prodotto
    // in questione aggiungerà il nome del prodotto alla lista, serve per il controllo appena sopra.
    if (!subscribedProducts.includes(product.name)) {
      this.subscriptionService.subscriptionData.next({
        show: false,
        isAlsoDiscord: true,
        isAssistenza: true,
        product: product,
        ownsProduct: true
      })
    } else {
      this.isAlreadySubscribed = true
    }
  } */

  async check_Subscription() {

  }

  /**
   * In caso il prodotto sia All Inclusive, verifico quando è stato acquistato,
   * se è stato acquistato meno di 90 giorni fa il periodo di prova sarà di 180 giorni,
   * altrimenti sarà di 7 giorni, il prezzo riservato agli utenti da < 90 giorni è caricato
   * in questo momento (qualora applicabile).
   * @param product
   */
/*   async checkPurchasedDate(product: MyProduct) {
    await this.productService.getMyProductWithDate(product.productId, true)
      .then((_product: ProductWithDate) => {
        if (this.assistenzaProduct == undefined)
          this.assistenzaProduct = this.myProduct.childs.find(x => x.productType === ProductType.Assistenza)
        this.assistenzaProduct.freeTrialDuration = _product.trialPeriod;
      }).catch((err) => {
        this.isProductSubscribable = false
      })
  } */

  /**
   * Metodo che funge da callback per il controllo abbonamento, se l'utente
   * è abbonato è questa funzione che lo stabilisce. Viene chiamata come callback
   * dal metodo presente nella subscription modal.
   * Questa funzione verifica che il controllo abbonamento non sia già stato effettuato,
   * per farlo utilizza un BehaviorSubject che contiene i nomi dei prodotti a cui l'utente
   * è abbonato, se arriva la conferma di abbonamento e non è presente il nome del prodotto
   * nel subject, viene aggiunto in modo che sia controllato senza effettuare chiamate la prossima
   * volta.
   * @param _ (evento, non usato ma obbligatorio)
   */
  async toggleAlreadySubscribed(_: any) {
    let subscribedProducts = this.productService.subscribedProducts.value
    if (!subscribedProducts.includes(this.myProduct.name))
      subscribedProducts.push(this.myProduct.name)
    this.isAlreadySubscribed = true
    await this.getMyProduct()
      .then(() => {
      })
      .finally(() => {
        this.loading = false;
      })
  }

/*   toggleSubscriptionModal() {
    if (this.assistenzaProduct === undefined)
      this.assistenzaProduct = this.myProduct.childs.find(x => x.productType === ProductType.Assistenza)
    this.subscriptionService.subscriptionData.next({
      show: true,
      product: this.assistenzaProduct,
      isAlsoDiscord: true,
      isAssistenza: true
    })
  } */

  async markVideoAsSeen(productId: number, isChecked: any) {
    if (isChecked.target.checked) {
      let seenVideos = this.lastSeenVideos
        .find(x => x.productId === this.productId)
        .seenVideos

      if (!seenVideos.includes(productId)) {
        this.lastSeenVideos
          .find(x => x.productId === this.productId)
          .seenVideos.push(productId)
      }

      localStorage.setItem('tta-lastSeenVideos', JSON.stringify(this.lastSeenVideos))

    } else if (!isChecked.target.checked) {
      this.lastSeenVideos
        .find(x => x.productId === this.productId)
        .seenVideos = this.lastSeenVideos
        .find(x => x.productId === this.productId)
        .seenVideos
        .filter(x => x !== productId)

      localStorage.setItem('tta-lastSeenVideos', JSON.stringify(this.lastSeenVideos))
    }
  }

  async getLastSeenVideos() {

    // controlla se un oggetto è vuoto
    async function isObjectEmpty(obj) {
      return Object.keys(obj).length === 0
    }

    // ottengo l'oggetto
    this.lastSeenVideos = JSON.parse(localStorage.getItem('tta-lastSeenVideos') || '[]')

    /**
     * crea l'oggetto iniziale inserendo tutte le sezioni disponibili,
     * lo salva in cache
     */
    async function initialSet() {
      let sections: Pagination[] = []

      const distinct = (value, index, self) => {
        return self.indexOf(value) === index
      }

      that.productSections = that.myProduct?.childs
        .map(x => x.productSection)
        .filter(distinct)
        .filter(x => x !== undefined)

      that.productSections.forEach((section: string) => {
        sections.push({
          section: section,
          pageNumber: 1
        })
      })
      let newVideo: LastSeenCourse = {
        productId: that.productId,
        pagination: sections,
        seenVideos: []
      }
      that.lastSeenVideos.push(newVideo)
      localStorage.setItem('tta-lastSeenVideos', JSON.stringify(that.lastSeenVideos))
    }

    // se l'oggetto è vuoto lo creo aggiungendo
    // i dati del video in cui sono
    if (await isObjectEmpty(this.lastSeenVideos)) {
      await initialSet()
    }

    // verifico se esiste gia il corso
    let course: LastSeenCourse = this.lastSeenVideos.find(x => x.productId === this.productId)

    // se non esiste lo aggiungo
    if (course === undefined || course === null) {
      await initialSet()
    }

    // controlla se ci sono sezioni non presenti nelle sezioni salvate in cache
    // se ce ne sono le aggiunge a un array che restituisce
    async function isSectionPresent(sections: Pagination[]) {

      let newSections: Pagination[] = []
      that.productSections.forEach((productSection: string, index) => {
        if (index < sections?.length) {
          let isPresent: boolean = sections[index].section == productSection
          if (!isPresent) {
            let newSection: Pagination = {
              section: productSection,
              pageNumber: 1
            }
            newSections.push(newSection)
          }
        }
      });

      return newSections
    }

    let newSections: Pagination[] = await isSectionPresent(course?.pagination)

    // aggiunge le nuove sezioni all'oggetto in cache
    if (newSections.length > 0) {
      this.lastSeenVideos.every(lastSeen => {
        if (lastSeen.productId === that.productId) {
          lastSeen.pagination.push(...newSections)
          return false;
        }
        return true;
      })

      localStorage.setItem('tta-lastSeenVideos', JSON.stringify(that.lastSeenVideos))
    }

    /**
     * Controlla se ci sono sezioni presenti in cache non più presenti nel prodotto,
     * quindi da eliminare
     * @param sections Sezioni da verificare
     * @returns Array di sezioni da eliminare o array vuoto
     */
    async function shouldSectionBeDeleted(sections: Pagination[]) {
      let toBeDeleted: Pagination[] = []
      sections.every(section => {
        let exists: boolean = that.productSections.includes(section.section)
        if (!exists) {
          toBeDeleted.push(section)
          return false
        }
        return true
      })

      return toBeDeleted
    }

    let toBeDeleted: Pagination[] = await shouldSectionBeDeleted(course?.pagination)

    // elimina le sezioni dall'oggetto in cache
    if (toBeDeleted.length > 0) {
      let currentIndex = 0
      let updatedPagination: Pagination[] = []
      this.lastSeenVideos.every((lastSeen, index) => {
        if (lastSeen.productId) {
          currentIndex = index
          updatedPagination = lastSeen.pagination.filter(x => !toBeDeleted.includes(x))
          return false
        }
        return true;
      })

      this.lastSeenVideos[currentIndex].pagination = updatedPagination

      localStorage.setItem('tta-lastSeenVideos', JSON.stringify(that.lastSeenVideos))
    }
  }

  /**
   * Prende la pagina corrente di ogni sezione (dall'oggetto lastSeenVideo) e la attribuisce
   * in modo che l'utente veda sempre le ultime pagine che ha visitato, per ogni sezione,
   * per ogni prodotto
   */
  async getCurrentPage() {
    let paginations: Pagination[][] =
      this.lastSeenVideos
        .filter(x => x.productId == this.productId)
        .map(x => x.pagination)

    paginations.forEach(pagination => {
      pagination.forEach((item, index) => {
        this.currentPage[index] = item.pageNumber
      })
    })
  }


  async pageChange(page: number, index: number, section: string) {
    // cambia pagine
    this.currentPage[index] = page
    // sincronizza la cache

    let _pagination = this.lastSeenVideos
      .find(x => x.productId === this.productId)
      .pagination.find(x => x.section === section)

    if (_pagination) {
      this.lastSeenVideos
        .find(x => x.productId === this.productId)
        .pagination.find(x => x.section === section)
        .pageNumber = page
    }

    localStorage.setItem('tta-lastSeenVideos', JSON.stringify(this.lastSeenVideos))
  }

  navigateAndReload(productId: number, name: string, productEnvironment: number): void {
  
    /* this.router.navigateByUrl(`/dashboard/my-product/${productId}/${name.split(' ').join('-').toLowerCase()}/${productEnvironment}`); */

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/dashboard/my-product/${productId}/${name.split(' ').join('-').toLowerCase()}/${productEnvironment}`]);
    });
  }
}
