import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fade } from '../../animations/fade.animation';
import { KindOfClient, SocialNetworks, SocialOutlets, Title } from '../../enums/registration.enum';
import { IRegisterRequest } from '../../requests/registration.request';
import { AuthService } from '../../services/auth.service';
import {
  CodiceUnivocoValidators,
  EmailPattern,
  FiscalCodeValidators,
  passwordMatching,
  PhonePattern,
  PostalCodeValidators,
  VatNumberValidators
} from "../../patterns/validators.patterns";

const defaultValues: IRegisterRequest = {
  title: '',
  kindOfClient: KindOfClient.Privato,
  firstName: '',
  lastName: '',
  userName: '',
  businessName: '',
  street: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
  fiscalCode: '',
  vatNumber: '',
  pec: '',
  codiceUnivoco: '',
  mostUsedSocial: SocialNetworks.Facebook,
  mostUsedSocialOutlets: SocialOutlets.WhatsApp,
  preferredContactHours: '8-20',
  acceptTerms: true,
  disclaimer: true,
  privacyTrattamento: true,
  privacyContatto: true,
  privacyThirdParty: true
}

@Component({
  selector: 'app-registration-modal',
  animations: [fade],
  templateUrl: './registration-modal.component.html',
  styleUrls: ['./registration-modal.component.scss']
})
export class RegistrationModalComponent implements OnInit {

  @Input() registerShow: boolean = false;
  @Output() registerModalCallback: EventEmitter<any> = new EventEmitter();

  title = Title
  socialNetworks = SocialNetworks
  socialOutlets = SocialOutlets
  kindOfClient = KindOfClient
  userTitle: number = 1;

  loading: boolean = false;
  succeeded: boolean = false;
  contactHours = ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']
  fromHour: string = ''
  toHour: string = ''
  request: IRegisterRequest = defaultValues;

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authenticationService: AuthService,
    private toastr: ToastrService) {
    this.form = this.formBuilder.group({
      title: new UntypedFormControl(this.request.title, [Validators.required]),
      kindOfClient: new UntypedFormControl(this.request.kindOfClient, [Validators.required]),
      firstName: new UntypedFormControl(this.request.firstName, [Validators.required]),
      lastName: new UntypedFormControl(this.request.lastName, [Validators.required]),
      userName: new UntypedFormControl(this.request.userName, [Validators.required, Validators.minLength(6)]),
      businessName: new UntypedFormControl(this.request.businessName),
      street: new UntypedFormControl(this.request.street, [Validators.required]),
      city: new UntypedFormControl(this.request.city, [Validators.required]),
      state: new UntypedFormControl(this.request.state, [Validators.required]),
      postalCode: new UntypedFormControl(this.request.postalCode, PostalCodeValidators),
      country: new UntypedFormControl(this.request.country, [Validators.required]),
      email: new UntypedFormControl(this.request.email, [Validators.required, Validators.email]),
      password: new UntypedFormControl(this.request.password, [Validators.required, Validators.minLength(8)]),
      confirmPassword: new UntypedFormControl(this.request.confirmPassword, [Validators.required]),
      phone: new UntypedFormControl(this.request.phone, [Validators.required, Validators.pattern(PhonePattern)]),
      fiscalCode: new UntypedFormControl(this.request.fiscalCode, FiscalCodeValidators),
      vatNumber: new UntypedFormControl(this.request.vatNumber),
      pec: new UntypedFormControl(this.request.pec),
      codiceUnivoco: new UntypedFormControl(this.request.codiceUnivoco),
      mostUsedSocial: new UntypedFormControl(this.request.mostUsedSocial, [Validators.required]),
      mostUsedSocialOutlets: new UntypedFormControl(this.request.mostUsedSocialOutlets, [Validators.required]),
      acceptTerms: new UntypedFormControl(this.request.acceptTerms, [Validators.requiredTrue]),
      disclaimer: new UntypedFormControl(this.request.disclaimer, [Validators.requiredTrue]),
      privacyTrattamento: new UntypedFormControl(this.request.privacyTrattamento, [Validators.requiredTrue]),
      privacyContatto: new UntypedFormControl(this.request.privacyContatto, [Validators.required]),
      privacyThirdParty: new UntypedFormControl(this.request.privacyThirdParty, [Validators.required]),
    }, {validator: passwordMatching})
  }

  get m() {
    return this.form.controls
  }

  ngOnInit(): void {
    this.setValidators()
    this.hoursRangeBuilder()
    this.request.invitedById = localStorage.getItem('tta-invite')?.replace(/['"]+/g, '')
  }

  toggleModal = (event?: any) => this.registerModalCallback.emit(this.registerShow)

  setValidators() {
    this.form.get('kindOfClient').valueChanges.subscribe(val => {
      if (val === KindOfClient.Impresa) {
        this.form.controls['businessName'].setValidators([Validators.required])
        this.form.controls['vatNumber'].setValidators(VatNumberValidators)
        this.form.controls['pec'].setValidators([Validators.required, Validators.pattern(EmailPattern)])
        this.form.controls['codiceUnivoco'].setValidators(CodiceUnivocoValidators)
      } else {
        this.form.controls['businessName'].setValidators(null)
        this.form.controls['vatNumber'].setValidators(null)
        this.form.controls['pec'].setValidators(null)
        this.form.controls['codiceUnivoco'].setValidators(null)
      }
    })
  }

  async onSubmit() {
    this.request.title = this.title[this.userTitle]
    if (this.form.valid) {
      this.loading = true
      await this.authenticationService.register(this.request)
        .then(() => {
          this.succeeded = true;
          this.toastr.success('Registrazione avvenuta con successo, controlla la mail per istruzioni su come proseguire', 'Registrazione avvenuta')
          this.toggleModal()
        })
        .catch(e => {
          /*console.log('errore registrazione', e)*/
          setTimeout(() => {
            this.succeeded = false;
          }, 1000)
          this.toastr.warning(e, 'Attenzione')
        }).finally(() => {
          this.loading = false;
        })
    } else {
      /*console.log(this.form.controls)*/
      this.toastr.warning('Tutti i campi sono obbligatori', 'Attenzione')
    }
  }

  hoursRangeBuilder() {
    if (this.fromHour === '' || this.toHour === '') {
      this.fromHour = '8'
      this.toHour = '20'
    }
    this.request.preferredContactHours = `${this.fromHour}-${this.toHour}`
  }

  hoursValidator() {
    if (parseInt(this.fromHour) > parseInt(this.toHour)) {
      this.fromHour = this.toHour
    }
  }
}
