import { PlatformType } from '@/helpers/enums/platformType.indicator';
import { ProductType } from '@/helpers/enums/productType.enum';
import { TypeCovers } from '@/helpers/enums/typeCovers.enum';
import { MyProduct } from '@/helpers/models/myProduct.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-product-platform',
  templateUrl: './my-product-platform.component.html',
  styleUrls: ['./my-product-platform.component.scss']
})
export class MyProductPlatformComponent implements OnInit {

  @Input() products: MyProduct[];
  @Input() type: ProductType;

  platformType = PlatformType;
  productType = ProductType;
  typeCovers = TypeCovers;
  availablePlatformType: PlatformType [] = null;

  constructor() { }

  async ngOnInit() {
    await this.getAvailablePlatformType(this.products)
  }

  async getAvailablePlatformType(products: MyProduct[]) {
    this.availablePlatformType = this.products
    .filter((thing, i, arr) => arr.findIndex(t => t.platformType === thing.platformType)  === i)
    .map(x => x.platformType);
  }
}
