import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderDetail } from '@models/orderDetail.model';
import { OrderDetailService } from '@services/orderDetails.service';
import { StripePaymentCheck } from '@models/stripePaymentCheck.model';
import { IAuthenticateResponse } from '@models/authenticate.model';
import { Order } from '@models/order.model';
import { AuthService } from '@services/auth.service';
import { OrderService } from '@services/orders.service';
import { ProductService } from '@services/products.service';
import { StripeTTAService } from '@services/stripeTTA.service';
import { ComponentCanDeactivate } from "@/helpers/guards/pendingChanges.guard";
import { Observable } from "rxjs";
import { Product } from "@models/product.model";
import { PayPalService } from "@services/paypal.service";
import { EmailService } from "@services/email.service";
import { SendEmailRequest } from "@requests/sendEmail.request";
import { SearchResult } from '@/helpers/models/searchResult.model';
import { HubSpotService } from '@services/hubspot.service';
import { PaymentType } from '@/helpers/enums/paymentType.enum';
import { ActionRequired } from '@/helpers/enums/actionRequired.enum';
import { ProductStatus } from '@/helpers/enums/productStatus.enum';
import { MyProduct } from '@/helpers/models/myProduct.model';

@Component({
  selector: 'app-checkout-successful',
  templateUrl: './checkout-successful.component.html',
  styleUrls: ['./checkout-successful.component.scss']
})
export class CheckoutSuccessfulComponent implements OnInit, ComponentCanDeactivate {

  productId: number
  sessionId: string = '';
  isPaypal = '';
  transactionStatus = '';
  transactionAmount = '';
  indicatorProductName: string = ''
  indicatorProductId: number = 0;
  loading = false;
  product: Product;
  currentUser: IAuthenticateResponse;
  orderId = 0; 

  constructor(
    private emailService: EmailService,
    private paypalService: PayPalService,
    private orderDetailService: OrderDetailService,
    private router: Router,
    private stripe: StripeTTAService,
    private toastr: ToastrService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private hubSpotService: HubSpotService,
    private auth: AuthService) {
    this.route.queryParams.subscribe(params => {
      this.sessionId = params['session_id'];
      this.isPaypal = params['isPaypal'];
      this.transactionStatus = params['tx_status'];
      this.transactionAmount = params['amount']
      this.indicatorProductName = params['indicatorProductName']
      this.indicatorProductId = params['indicatorProductId']
      this.productId = Number(params['productId'])
    });
    this.loading = true;
    this.currentUser = this.auth.currentUserValue
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.loading;
  }

  async ngOnInit() {
    await this.getCurrentOrderId(this.currentUser.accountId)
      .then(async (_currentOrderId: number) => {
        if (this.sessionId != '' && (this.isPaypal === undefined || this.isPaypal === '')) {
          await this.isPaymentSuccessful(this.sessionId)
            .then(async (result: StripePaymentCheck) => {
              if (result.paymentIntentStatus === 'succeeded') {
                await this.orderDetailService.loadCart(this.currentUser.accountId)
                  .then(async (details: SearchResult<OrderDetail>) => {
                    if (details.totalCount > 0) {
                      for (const detail of details.result) {
                        await this.productService.getProductMinimal(detail.productId)
                          .then(async (_product: MyProduct) => {
                            this.product = _product;
                          })
                        let orderDetail: OrderDetail = {
                          accountId: this.currentUser.accountId, 
                          productId: detail.productId,
                          orderId: _currentOrderId,
                          quantity: detail.quantity,
                          orderStatus: this.product && this.product.actionRequired === ActionRequired.ContractSignature ? ProductStatus.Unsigned : ProductStatus.Active,
                          duration: detail.duration,
                          evasion: detail.evasion,
                          freeTrialDuration: detail.freeTrialDuration,
                          price: detail.price,
                          typePurchases: detail.typePurchases
                        };
                        await this.orderDetailService.createOrderDetail(orderDetail)
                          .then(async () => {
                            //Elimino carrello:
                            await this.orderDetailService.deleteOrderDetail(this.currentUser.accountId, detail.productId, 0)
                          })
                      }
                    }
                  })
                let order: Order = {
                  orderId: _currentOrderId,
                  accountId: this.currentUser.accountId,
                  // Add
                  externalTransactionId: result.paymentIntentId,
                  amountPaid: result.amountTotal
                };
                await this.orderService.updateOrder(order)
                  .then(async (_order) => {
                    this.toastr.success("Grazie per l'acquisto!", 'Acquisto completato');
                    this.loading = false;
                    this.productService.updateProductCount(0);
                    // empty cart and redirect
                    this.productService.emptyCart();
                    this.productService.myProducts.next([])
                    await this.router.navigate(['/dashboard/my-products'])
                  }).catch(async () => {
                    this.toastr.warning("Errore nell'elaborazione dell'ordine, siamo a conoscenza del problema e stiamo lavorando per sistemarlo. Ci scusiamo per il disagio.",
                      'Attenzione')
                    const emailRequest: SendEmailRequest = {
                      fullName: this.currentUser?.firstName + this.currentUser?.lastName,
                      email: 'mattia.tech@ttgames.it',
                      message: `Errore attribuzione prodotto ${order}`
                    }
                    await this.emailService.sendEmail(emailRequest)
                  })
                  .finally(() => {
                    this.productService.emptyCart();
                    this.loading = false
                  })
              }
            })
        } else {
          await this.isPaypalSuccessful(this.sessionId, this.transactionStatus, this.transactionAmount)
        }
      })
  }

  async isPaymentSuccessful(sessionId: string) {
    return await this.stripe.getPaymentIntentStatus(sessionId)
  }

  async isPaypalSuccessful(transactionId: string, transactionStatus: string, amount: string) {
    this.productService.getProductMinimal(this.productId)
      .then(async (_product: Product) => {
        this.product = _product;
        transactionId = localStorage.getItem(`paypalOrder-${this.currentUser.accountId}`)
        await this.paypalService.sendPurchaseConfirmationEmail(
          this.currentUser.firstName,
          this.currentUser.email,
          this.product.name,
          this.product.imageUrl)
          .then(() => {
          })
          .catch(() => {
            this.toastr.warning("Non siamo riusciti ad inviarti una mail di conferma ma il tuo ordine è andato a buon fine",
              "Attenzione");
          })
        localStorage.removeItem(`paypalOrder-${this.currentUser.accountId}`)
        let num = parseFloat(amount);

        await this.orderDetailService.loadCart(this.currentUser.accountId)
        .then(async (details: SearchResult<OrderDetail>) => {
          if (details.totalCount > 0) {
            for (const detail of details.result) {
              let orderDetail: OrderDetail = {
                accountId: this.currentUser.accountId, 
                productId: detail.productId,
                orderId: this.orderId,
                quantity: detail.quantity,
                orderStatus: this.product.actionRequired === ActionRequired.ContractSignature ? ProductStatus.Unsigned : ProductStatus.Active,
                duration: detail.duration,
                evasion: detail.evasion,
                freeTrialDuration: detail.freeTrialDuration,
                price: detail.price,
                typePurchases: detail.typePurchases
              };
              await this.orderDetailService.createOrderDetail(orderDetail)
                .then(async () => {
                  //Mando i dati a HubSpot:
                  await this.hubSpotService.hubSpotConfirmPurchase(this.currentUser.email, this.product.name, parseFloat(amount), PaymentType.PayPal)          

                  //Elimino carrello:
                  await this.orderDetailService.deleteOrderDetail(this.currentUser.accountId, detail.productId, 0)
                })
            }
          }
        })

        let order: Order = {
          orderId: this.orderId,
          accountId: this.currentUser.accountId,
          externalTransactionId: transactionId,
          amountPaid: num
        };
        this.orderService.updateOrder(order)
          .then(async (_order: Order) => {
            this.toastr.success(`Grazie per aver acquistato ${this.product.name}!`, 'Acquisto Completato!', {
              timeOut: 10000
            })
          }).catch(() => {
          this.toastr.warning("Errore nell'aggiornamento dell'ordine", 'Errore');
        })
          .finally(async () => {
            this.productService.updateProductCount(0);
            this.productService.emptyCart();
            this.loading = false;
            this.productService.myProducts.next([])
            await this.router.navigate(['/dashboard/my-products'])
          })
      })
      .catch(() => {

      })
  }

  async getCurrentOrderId(accountId: string) {
    return await this.orderService.getCurrentOrderId(accountId)
      .then(res => {
        return this.orderId = res
      })
  }
}
