<div class="relative py-6 px-4 sm:px-6 slide-in-blurred-top bg-transparent">
  <nav aria-label="Global" class="relative flex items-center justify-center sm:h-10">
    <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
      <div class="flex items-center justify-between w-full md:w-auto">
        <a [routerLink]="['/']" aria-label="Home">
          <picture>
            <source srcset="../../../../assets/images/logo-rosso-verde.webp" type="image/webp">
            <img alt="Top-Trading Academy Logo"
                 class="filter invert transition-std ease-in-out hover:scale-105 hidden lg:block"
                 width="150" height="29" id="Top-Trading Academy Logo"
                 src="../../../../assets/images/logo-rosso-verde.png">
          </picture>
        </a>
      </div>
    </div>
    <div (click)="toggleMenuDropdown()" *ngIf="currentUser?.jwtToken"
         class="cursor-pointer absolute right-12 top-1">
      <div *ngIf="currentUser" [@fade]="currentUser" class="m-auto items-center">
        <span
          *ngIf="currentUser?.avatarUrl == '' || currentUser?.avatarUrl == null || currentUser?.avatarUrl.includes('/.webp')"
          [@fade]="currentUser"
          class="p-2 text-xs rounded-full shadow-darkHeavy bg-redCustom border-2 border-black/10 uppercase">
          {{ currentUser?.firstName.charAt(0) }}{{ currentUser?.lastName.charAt(0) }}
        </span>
        <img
          *ngIf="currentUser?.avatarUrl != '' && currentUser?.avatarUrl != null  && !currentUser?.avatarUrl.includes('/.webp')"
          [@fade]="currentUser?.avatarUrl"
          [src]="currentUser?.avatarUrl.includes('discordapp') ? currentUser?.avatarUrl : serverUrl + currentUser?.avatarUrl"
          class="rounded-full inline-block w-8" [alt]="'Immagine di profilo di' + currentUser?.firstName">
      </div>
    </div>
    <div class="hidden lg:block md:ml-10 md:pr-6 lg:space-x-2 xl:space-x-4">
      <a [routerLink]="['/shop']" class="desktop-menu-text" id="shop">Shop</a>
      <!--<a [routerLink]="['/radio-radio']" class="desktop-menu-text"
         id="radio-radio">Radio Radio</a>-->
      <!--<a [routerLink]="['/obiettivo-250']" class="desktop-menu-text"
         id="obiettivo-250">Obiettivo 250</a>-->
      <a *ngIf="currentUser?.jwtToken" [routerLink]="['/dashboard', 'my-products']"
         class="desktop-menu-text" id="myproducts">
        I miei Prodotti
      </a>
      <a [routerLink]="['/ammiratore']" class="desktop-menu-text"
         id="ammiratore">Ammiratore</a>
      <a *ngIf="currentUser?.jwtToken" [@fade]="currentUser"
         [routerLink]="['/dashboard/request-indicators']"
         class="desktop-menu-text" id="indicators">Indicatori</a>
      <a *ngIf="currentUser?.jwtToken" [@fade]="currentUser" [routerLink]="['/dashboard/profile']"
         class="desktop-menu-text" id="profile">Profilo</a>
      <ng-container *ngIf="currentUser?.jwtToken && allowedRoles.includes(currentUser?.role)">
        <a [@fade]="currentUser" [routerLink]="['/king-of-trading/landing']"
           class="desktop-menu-text" id="landing">King of Trading</a>
        <a [@fade]="currentUser" [routerLink]="['/king-of-trading/levels']"
           class="desktop-menu-text" id="levels">Livelli</a>
      </ng-container>
<!--      Sezione Ebook nascosta.-->
<!--      <a *ngIf="currentUser?.jwtToken" [@fade]="currentUser" [href]="ebookUrl" target="_blank" class="desktop-menu-text" id="ebook">Ebook</a>-->
      <a [routerLink]="['/help']" class="desktop-menu-text" id="help">Aiuto</a>
      <a [routerLink]="['/contacts']" class="desktop-menu-text"
         id="contact">Contatti</a>
    </div>
    <div (mouseleave)="toggleMenuDropdown()" *ngIf="menuDropdown" [@fade]="menuDropdown"
         aria-labelledby="menu-button"
         aria-orientation="vertical"
         class="origin-top-right absolute top-6 right-0 mt-2 w-24 rounded-md shadow-lg bg-grayBgLight ring-1 ring-black ring-opacity-5 focus:outline-none"
         role="menu" tabindex="-1">
      <div class="py-1 text-gray-300" role="none">
        <a [routerLink]="['/dashboard/profile']"
           class="cursor-pointer block px-4 py-2 text-sm transition-std ease-in-out transform hover:text-redCustom"
           id="expandable-menu-profile" role="menuitem" tabindex="-1">Profilo</a>
        <a [routerLink]="['account/forgot-password']"
           class="cursor-pointer block px-4 py-2 text-sm transition-std ease-in-out transform hover:text-redCustom"
           id="expandable-menu-password-reset" role="menuitem" tabindex="-1">Reset Password</a>
        <a (click)="logOut()"
           class="cursor-pointer block px-4 py-2 text-sm transition-std ease-in-out transform hover:text-redCustom"
           id="expandable-menu-logout" role="menuitem" tabindex="-1">Log Out</a>
      </div>
    </div>
  </nav>
</div>
<!-- Mobile menu, show/hide based on menu open state. -->
<div class="lg:hidden">
  <input [checked]="toggle" id="hamburger" name="hamburger" type="checkbox"/>
  <svg>
    <defs>
      <filter id="gooeyness">
        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="2.2"/>
        <feColorMatrix in="blur" mode="matrix" result="gooeyness"
                       values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10"/>
        <feComposite in="SourceGraphic" in2="gooeyness" operator="atop"/>
      </filter>
    </defs>
  </svg>
  <div class="plates">
    <div (click)="toggleMenu()" class="plate plate7" id="menu" onclick="this.classList.toggle('active')">
      <svg class="burger" height="100" version="1.1" viewBox="0 0 100 100" width="100">
        <path class="line line1"
              d="M 30,35 H 60 C 63.595663,35 65,32.357023 65,30 C 65,27.642977 62.357023,25 60,25 C 57.642977,25 55,25.933659 55,30 V 77.828008"/>
        <path class="line line2"
              d="M 70,35 H 50 C 46.404337,35 45,32.357023 45,30 C 45,27.642977 47.642977,25 50,25 C 52.357023,25 55,25.933659 55,30 V 77.828008"/>
        <path class="line line3"
              d="M 30,50 H 55 C 58.595665,50 60.000005,47.357023 60.000005,45 C 60.000005,42.642977 57.357025,40 55,40 C 52.642977,40 50,40.933659 50,45 V 92.828008"/>
        <path class="line line4"
              d="M 70,50 H 45 C 41.404337,50 40,47.357023 40,45 C 40,42.642977 42.642977,40 45,40 C 47.357023,40 50,40.933659 50,45 V 92.828008"/>
        <path class="line line5"
              d="M 30,65 H 50 C 53.595665,65 55.000005,62.357023 55.000005,60 C 55.000005,57.642977 52.357025,55 50,55 C 47.642977,55 45,55.933659 45,60 V 107.82801"/>
        <path class="line line6"
              d="M 70,65 H 40 C 36.404337,65 35,62.357023 35,60 C 35,57.642977 37.642977,55 40,55 C 42.357023,55 45,55.933659 45,60 V 107.82801"/>
      </svg>
      <svg class="x" height="100" version="1.1" viewBox="0 0 100 100" width="100">
        <path class="line" d="M 34,32 L 66,68"/>
        <path class="line" d="M 66,32 L 34,68"/>
      </svg>
    </div>
  </div>
  <div class="menu-container">
    <div class="menu"></div>
    <div (click)="toggleMenu(); animateMenu()"
         class="flex flex-col links text-black w-64 text-xl xs:text-2xl space-y-4 xs:space-y-8"
         style="z-index: 1000000;">
      <a [routerLink]="['/']" class="mobile-menu-text" id="home-mobile">Home</a>
      <a [routerLink]="['/shop']" class="mobile-menu-text" id="shop-mobile">Shop</a>
      <!-- <a [routerLink]="['/radio-radio']" class="mobile-menu-text" id="radio-radio-mobile">
         Radio Radio
       </a>-->
      <!--<a [routerLink]="['/obiettivo-250']" class="mobile-menu-text" id="obiettivo-250-mobile">
        Obiettivo 250
      </a>-->
      <a *ngIf="currentUser?.jwtToken" [routerLink]="['/dashboard', 'my-products']"
         class="mobile-menu-text" id="my-products-mobile">Miei Prodotti</a>
      <a [routerLink]="['/ammiratore']" class="mobile-menu-text" id="ammiratore-mobile">Ammiratore</a>
      <a *ngIf="currentUser?.jwtToken" [routerLink]="['/dashboard', 'request-indicators']"
         class="mobile-menu-text" id="indicators-mobile">Indicatori</a>
      <a *ngIf="currentUser?.jwtToken" [routerLink]="['/dashboard', 'profile']"
         class="mobile-menu-text" id="dashboard-mobile">Profilo</a>
      <ng-container *ngIf="currentUser?.jwtToken && allowedRoles.includes(currentUser?.role)">
        <a [routerLink]="['/king-of-trading/landing']"
           class="mobile-menu-text" id="kot-mobile">King of Trading</a>
        <a [routerLink]="['/king-of-trading/levels']"
           class="mobile-menu-text" id="profile-mobile">Livelli</a>
      </ng-container>
      <a [routerLink]="['/help']" class="mobile-menu-text" id="help-mobile">Aiuto</a>
      <a [routerLink]="['/contacts']" class="mobile-menu-text" id="contact-mobile">Contatti</a>
      <div *ngIf="!currentUser?.jwtToken"
           class="flex flex-row mx-auto space-x-4 mt-24 border-t-2 border-black pt-8 font-semibold">
        <span (click)="openLogin()" class="mobile-menu-texte" id="login-mobile">Login</span>
      </div>
    </div>
  </div>
</div>
