import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Params, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ProductType} from '@enums/productType.enum';
import {IAuthenticateResponse} from '@models/authenticate.model';
import {Product} from '@models/product.model';
import {AuthService} from '@services/auth.service';
import {ProductService} from '@services/products.service';
import {environment} from '@envs/environment';
import {OrderDetailService} from '@services/orderDetails.service';
import {OrderDetail} from '@models/orderDetail.model';
import {StripeTTAService} from '@services/stripeTTA.service';
import {ProductAvailability} from '@enums/productAvailability.enum';
import {SEOService} from '@services/seo.service';
import {IndicatorService} from '@services/indicators.service';
import {SearchResult} from '@models/searchResult.model';
import {Indicator} from '@models/indicator.model';
import {BehaviorSubject, Subscription} from 'rxjs';
import {fade} from '@animations/fade.animation';
import {BankAccountService} from '@services/bankAccounts.service';
import {value} from '@animations/value.animation';
import {AutoUnsubscribe} from "ngx-auto-unsubscribe-decorator";
import {ProductEnvironment} from "@enums/productEnvironment.enum";
import {SubscriptionService} from "@services/subscription.service";
import {ProductWithDate} from "@models/productWithDate.model";
import {take} from "rxjs/operators";
import {TypeCovers} from '@/helpers/enums/typeCovers.enum';
import {ProductChild} from '@/helpers/models/productChild.model';
import {TypePurchases} from '@/helpers/enums/typePurchases.enum';

let that: any;

const defaultValues: OrderDetail = {
  accountId: '',
  productId: 0,
  orderId: 0,
  quantity: 1,
  orderStatus: 1,
  duration: 0,
  evasion: 0,
  freeTrialDuration: 0,
  price: 0,
  typePurchases: TypePurchases.Days
}

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  animations: [fade, value]
})
export class ProductDetailComponent implements OnInit {

  @ViewChild("paypalDetailRef") paypalDetailRef: ElementRef

  serverUrl: string = environment.storageUrl;
  /*paypalOrderId: string = ''*/
  productId!: number;
  productType = ProductType
  productAvailability = ProductAvailability
  typeCovers = TypeCovers;

  product!: Product
  productChildren: Product[] = [];
  assistenzaChildren: Product[] = [];
  indicatoriChildren: Product[] = [];
  products: Product[] = [];
  indicatorRequestMade: number = undefined
  productChildrenOptional: Product[] = []
  assistenzaProductOptional: Product[] = []
  productChild!: Product
  orderIdSubscription: number

  loading: boolean = false
  loadingAction: boolean = false;
  /*loadingCoinAction: boolean = false;*/
  childLoading: boolean = false;
  childLoaded: boolean = false;
  succeeded: boolean = false;
  productAlreadyOwned: [boolean, boolean] = [false, false];
  showIntroVideo: boolean = false;
  hasProductBeenAddedToBasket: boolean = true;
  isAbleToRenew: boolean = true
  isAlreadySubscribed: boolean = false
  isVolumasterUser: boolean = false

  shouldLoadPaypal = new BehaviorSubject<boolean>(false);
  shouldLoadPaypal$ = this.shouldLoadPaypal.asObservable()

  @AutoUnsubscribe()
  userSubscription: Subscription
  @AutoUnsubscribe()
  payPalSubscription: Subscription;
  @AutoUnsubscribe()
  productSubscription: Subscription;

  orderDetail = defaultValues;
  currentUser: IAuthenticateResponse;

  amountOfSubscriptions: number = 0
  /*userBalance: number = 0*/
  currentPage: number[] = []

  defaultImage: string = "https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/tt_logo.png"
  productSections: string[] = []
  productSectionsOptional: string[] = []

  constructor(
    private subscriptionService: SubscriptionService,
    private bankAccountService: BankAccountService,
    private indicatorService: IndicatorService,
    private router: Router,
    private stripeService: StripeTTAService,
    private toastr: ToastrService,
    private seo: SEOService,
    private route: ActivatedRoute,
    private productService: ProductService,
    private orderDetailService: OrderDetailService,
    private authService: AuthService) {
    that = this
    this.userSubscription = this.authService.currentUser.subscribe(user => {
      if (user) {
        this.currentUser = user
        this.orderDetail.accountId = this.currentUser.accountId;
      }
    })
    this.route.params.subscribe(params => {
      this.productId = parseInt(params['productId'])
      this.seo.updateTitle(`${params['productName']
        .split('-')
        .join(' ')
        .toLowerCase()
        .replace(/\b\w/g, (s: string) => s.toUpperCase())} | Top-Trading Academy`)
    })
  }


  /**
   * Carica prodotto da observable o, se non presente, da api.
   * In caso fosse una membership, controlla se l'utente è abbonato
   * In caso fosse un pacchetto indicatori, controlla se l'ultima richiesta fatta (ove presente) è scaduta
   */
  async ngOnInit() {
    this.productSubscription = this.productService.productDetails.subscribe(async products => {
      if (products.some(x => x.productId === this.productId)) {
        this.product = products.find(x => x.productId == this.productId)
        if (this.product.productAvailability === ProductAvailability.NonDisponibile) {
          this.toastr.warning("Questo prodotto non è attualmente disponibile", "Attenzione")
          await this.router.navigateByUrl('/shop')
        }
        this.seo.updateDescription(this.product.description)
        if (this.currentUser?.jwtToken && (this.product.productType === ProductType.Pacchetto_Indicatori))
          await this.checkAlreadyRequested()
        if (this.currentUser?.jwtToken && this.product.productType === ProductType.Membership) {
          await this.checkMyProductOwnership(this.product)
          //this.checkSubscription(this.product)
        }
        await this.subscribeToPayPalRender()
        if (this.productChildren.length != 0) {
          await this.getChildren()
          await this.GetChildrenOptional()
        }
      } else {
        if (this.productId > 0 && this.productId != undefined) {
          const getProduct = this.getProduct()
          const getChildren = await this.getChildren()
          const getChildrenOptional = await this.GetChildrenOptional()
          await Promise.all([getProduct, getChildren, getChildrenOptional])
        }
      }
    })
  }

  /**
   * Controlla se l'utente è in possesso del prodotto Volumaster,
   * in caso affermativo, setta la variabile isVolumasterUser a true,
   * modifica il prezzo visibile e il prezzo stripe.
   * Utilizzato solo per ammiratore per differenziare il costo (utenti Volumaster pagano meno)
   * @param product
   */
  async checkMyProductOwnership(product: Product) {
    if (product.productType === this.productType.Membership && product.name === 'Ammiratore') {
      const productIdToCheck: number = Number(this.product.stripeSubscriptionPriceId.split(';')[0])
      await this.productService.getMyProductWithDate(productIdToCheck, false)
        .then((_product: ProductWithDate) => {
          this.isVolumasterUser = true
          // nuovo prezzo, numero, da mostrare all'utente
          this.product.price = Number(this.product.stripeSubscriptionPriceId.split(';')[1])
          // nuovo prezzo, stringa, da inviare a stripe
          this.product.stripePriceId = this.product.stripeSubscriptionPriceId.split(';')[2]
        })
        .catch(() => {
        })
    }
  }

  /**
   * Carica prodotto da API e si sottoscrive al render di paypal
   * (controlli presenti nella funziona per il rendering condizionale)
   */
  async getProduct() {
    this.loading = true
    return await this.productService.getProduct(this.productId)
      .then(async (_product: Product) => {
        let _products: Product[] = this.productService.productDetails.value
        _products.push(_product)
        this.productService.productDetails.next(_products)
        await this.subscribeToPayPalRender()
        this.seo.updateDescription(this.product.description)
      }).catch(() => {
        this.toastr.warning('Errore del caricamento del prodotto.', 'Attenzione')
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
  }

  /**
   * controlla se l'utente è abbonato al prodotto o a una sua assistenza
   * permettere di non verificare molteplici volte che l'utente abbia il permesso discord
   * @param product
   */

  /*   checkSubscription(product: Product) {
      const subscribedProducts: string[] = this.productService.subscribedProducts.value
      if (product != undefined && !subscribedProducts.includes(product.name)) {
        this.subscriptionService.subscriptionData.next({
          show: false,
          isAlsoDiscord: true,
          isAssistenza: false,
          product: product,
          ownsProduct: false
        })
      } else {
        this.isAlreadySubscribed = true
      }
    } */

  /**
   * Cambia il flag che stabilisce se l'utente è abbonato al prodotto
   * che sta visualizzando
   * @param _event
   */
  toggleAlreadySubscribed(_event: any) {
    let subscribedProducts = this.productService.subscribedProducts.value
    if (!subscribedProducts.includes(this.product.name))
      subscribedProducts.push(this.product.name)
    this.isAlreadySubscribed = true
  }

  /**
   * Apre la modal di abbonamento.
   */
  async toggleSubscriptionChildModal(childId: number) {
    await this.isProductAlreadyOwned(this.productId)

    if (this.product.price != 0 && (!this.productAlreadyOwned[0] || !this.productAlreadyOwned[1])) {
      this.toastr.warning('Non sei in possesso del prodotto relativo a questa assistenza.', 'Attenzione')
      return
    }
    this.hasProductBeenAddedToBasket = false
    await this.isProductAlreadyOwned(childId)

    if (this.productAlreadyOwned[0]) { // Se possiede il prodotto:
      this.hasProductBeenAddedToBasket = false

      if (this.productAlreadyOwned[1]) // Se possiede il prodotto ed è attivo:
        this.toastr.warning('Sei già in possesso di questo prodotto.\r\nVisualizzalo nella sezione I Miei Prodotti', 'Attenzione')
      else if (!this.productAlreadyOwned[1]) // Se possiede il prodotto ed è disattivato:
        this.toastr.warning("Sei in possesso di questo prodotto ma è stato disattivato. Contatta l'assistenza clienti.", 'Attenzione')

      this.loadingAction = false;
    } else {
      await this.productService.getProduct(childId)
        .then(async (result: Product) => {
          await this.productService.getProductChild(this.productId, childId)
            .then((productChild: ProductChild) => {
              result.typePurchases = productChild.typePurchases
              result.duration = productChild.duration
              result.freeTrialDuration = productChild.freeTrialDuration
              result.price = productChild.priceTantum
              result.subscriptionCycle = productChild.duration
            })

          /* if (this.currentUser && this.currentUser.jwtToken) {
         this.orderDetail.productId = result.productId
         this.orderDetail.typePurchases = result.typePurchases
         this.orderDetail.freeTrialDuration = result.freeTrialDuration
         this.orderDetail.duration = result.duration
         this.orderDetail.price = result.price
         await this.orderDetailService.createBasketOrder(this.orderDetail)
           .then(async (_orderDetail: OrderDetail) => {
             //this.orderIdSubscription = _orderDetail.orderId
             //this.succeeded = true;
           }).catch(() => {
             //this.hasProductBeenAddedToBasket = false
             //this.succeeded = false;
             this.toastr.warning("Errore nell'aggiunta ordine.", 'Attenzione')
           }).finally(() => {
             //setTimeout(() => {
             //this.loadingAction = false;
             //}, 1000)
           })
         }    */

          this.subscriptionService.subscriptionData.next({
            show: true,
            isAlsoDiscord: true,
            isAssistenza: !!result,
            product: result,
            ownsProduct: false,
            orderId: this.orderIdSubscription
          });
        })
    }
  }

  async toggleSubscriptionModal() {
    this.hasProductBeenAddedToBasket = false
    await this.isProductAlreadyOwned(this.productId)

    if (this.productAlreadyOwned[0]) { // Se possiede il prodotto:
      this.hasProductBeenAddedToBasket = false

      if (this.productAlreadyOwned[1]) // Se possiede il prodotto ed è attivo:
        this.toastr.warning('Sei già in possesso di questo prodotto.\r\nVisualizzalo nella sezione I Miei Prodotti', 'Attenzione')
      else if (!this.productAlreadyOwned[1]) // Se possiede il prodotto ed è disattivato:
        this.toastr.warning("Sei in possesso di questo prodotto ma è stato disattivato. Contatta l'assistenza clienti.", 'Attenzione')

      this.loadingAction = false;
    } else {
      await this.productService.getProduct(this.productId)
        .then(async (result: Product) => {
          this.subscriptionService.subscriptionData.next({
            show: true,
            isAlsoDiscord: true,
            isAssistenza: !!result,
            product: result,
            ownsProduct: false,
            orderId: this.orderIdSubscription
          });
        })
    }
  }

  /**
   * Renderizza il bottone PayPal se le condizioni sono rispettate
   */
  async subscribeToPayPalRender() {
    const shouldNotBePayedPaypal: ProductType[] = [ProductType.Membership, ProductType.Pacchetto_Indicatori, ProductType.Indicatore]
    if (this.amountOfSubscriptions < 1) {
      this.amountOfSubscriptions = 1
      this.payPalSubscription = this.shouldLoadPaypal$.subscribe(shouldIDoIt => {
        if (shouldIDoIt && !this.currentUser.jwtToken && (!shouldNotBePayedPaypal.includes(this.product?.productType) || (this.product.productType === ProductType.Indicatore && this.product.typeCovers !== TypeCovers.Cover))) {
          this.products.push(this.product)
          this.payPalSubscription.unsubscribe()
          setTimeout(() => {
            this.renderPaypal()
          }, 250)
        }
      })
    }
  }

  async getCategories() {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index
    }

    this.productSections = this.productChildren
      .map(x => x.productSection)
      .filter(distinct)
  }

  async getCategoriesOptional() {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index
    }

    this.productSectionsOptional = this.productChildrenOptional
      .map(x => x.productSection)
      .filter(distinct)
  }

  /**
   * It gets the child products of the current product.
   * @returns The result of the search.
   */
  async getChildren() {
    //this.childLoading = true;
    if (this.product?.productType === ProductType.Membership || this.childLoaded) return
    return await this.productService.searchProducts('', 0, 0, this.productId, null, ProductEnvironment.Shop, null, false)
      .then(async (_children: SearchResult<Product>) => {
        this.shouldLoadPaypal.next(true)

        if (_children.totalCount > 0) {
          this.productChildren = _children.result
            .filter((x: Product) => x.productType !== ProductType.IntroCorso
              && x.productType !== ProductType.Pacchetto_Indicatori);
          /*x.productType !== ProductType.Assistenza
          &&*/

          await this.getCategories()

          /*           // filtra prodotti assistenza
                    this.assistenzaChildren = _children.result
                      .filter((x: Product) => x.productType === ProductType.Assistenza); */

          // filtra prodotti indicatori
          this.indicatoriChildren = _children.result
            .filter((x: Product) => x.productType === this.productType.Indicatore);
        } else {
          this.productChildren = []
        }
      }).catch(async () => {
        if (this.product.productType == ProductType.Indicatore && this.product.typeCovers == TypeCovers.Cover) {
          this.productChildren.push(this.product)
          await this.getCategories()
        }
      })
      .finally(() => {
        this.childLoading = false
        this.childLoaded = true
      })
  }

  async GetChildrenOptional() {
    //if (this.product?.productType === ProductType.Membership || this.childLoaded) return
    return await this.productService.searchProducts('', 0, 0, this.productId, null, ProductEnvironment.Shop, null, true)
      .then(async (_children: SearchResult<Product>) => {
        this.shouldLoadPaypal.next(true)
        if (_children.totalCount > 0) {
          this.productChildrenOptional = _children.result
            .filter((x: Product) => x.productType !== ProductType.IntroCorso
              && x.productType !== ProductType.Pacchetto_Indicatori
              && x.productType !== ProductType.Assistenza);
          /*x.productType !== ProductType.Assistenza
          &&*/

          this.assistenzaProductOptional = _children.result.filter((x: Product) => x.productType == ProductType.Assistenza);

          await this.getCategoriesOptional()
          /*
                    // filtra prodotti assistenza
                    this.assistenzaChildren = _children.result
                      .filter((x: Product) => x.productType === ProductType.Assistenza); */

          // filtra prodotti indicatori
          this.indicatoriChildren = _children.result
            .filter((x: Product) => x.productType === this.productType.Indicatore);
        } else {
          this.productChildren = []
        }
      }).catch(async () => {
        if (this.product.productType == ProductType.Indicatore && this.product.typeCovers == TypeCovers.Cover) {
          this.productChildren.push(this.product)
          await this.getCategories()
        }
      })
      .finally(() => {
        this.childLoading = false
        this.childLoaded = true
      })
  }

  /**
   * Add the product to the basket and if the user is logged in, add the product to the DB
   */
  async addToBasket() {
    //this.loadingAction = !this.loadingAction;
    let basketProducts = this.productService.basketProducts
    if (basketProducts.some(x => x.productId === this.product.productId)) {
      this.toastr.warning("Hai già aggiunto il prodotto al carrello")
      this.loadingAction = false
      return
    }
    // controllo che l'utente abbia il prodotto
    if (this.currentUser && this.currentUser.jwtToken)
      await this.isProductAlreadyOwned(this.productId)

    // utente ha il prodotto
    if (this.productAlreadyOwned[0]) {
      this.hasProductBeenAddedToBasket = false
      // ha il prodotto ed è attivo
      if (this.productAlreadyOwned[1])
        this.toastr.warning('Sei già in possesso di questo prodotto.\r\nVisualizzalo nella sezione I Miei Prodotti', 'Attenzione')
      // ha il prodotto ma è disattivato
      else if (!this.productAlreadyOwned[1])
        this.toastr.warning("Sei in possesso di questo prodotto ma è stato disattivato. Contatta l'assistenza clienti.", 'Attenzione') // : this.toastr.warning('Sei già in possesso di questo prodotto.', 'Attenzione')
      this.loadingAction = false;
    } else {
      // utente non ha il prodotto quindi aggiungo al carrello
      this.productService.addProductToBasket(this.product);
      // se utente è loggato aggiungo il prodotto nel DB
      if (this.currentUser && this.currentUser.jwtToken) {
        this.orderDetail.productId = this.product.productId
        this.orderDetail.typePurchases = this.product.typePurchases
        this.orderDetail.freeTrialDuration = this.product.freeTrialDuration
        this.orderDetail.duration = this.product.duration
        this.orderDetail.price = this.product.price
        await this.orderDetailService.createBasketOrder(this.orderDetail)
          .then(async () => {
            this.hasProductBeenAddedToBasket = true
            this.succeeded = true;
          }).catch(() => {
            this.hasProductBeenAddedToBasket = false
            this.succeeded = false;
            this.toastr.warning("Errore nell'aggiunta al carrello.", 'Attenzione')
          }).finally(() => {
            setTimeout(() => {
              this.loadingAction = false;
            }, 1000)
          })
      }
    }
  }

  async addChildToBasket(childId: number) {
    this.loadingAction = !this.loadingAction;
    let basketProducts = this.productService.basketProducts
    if (basketProducts.some(x => x.productId === childId)) {
      this.toastr.warning("Hai già aggiunto il prodotto al carrello")
      this.loadingAction = false
      return
    }
    // controllo che l'utente abbia il prodotto
    if (this.currentUser && this.currentUser.jwtToken)
      await this.isProductAlreadyOwned(childId)

    // utente ha il prodotto
    if (this.productAlreadyOwned[0]) {
      this.hasProductBeenAddedToBasket = false
      // ha il prodotto ed è attivo
      if (this.productAlreadyOwned[1])
        this.toastr.warning('Sei già in possesso di questo prodotto.\r\nVisualizzalo nella sezione I Miei Prodotti', 'Attenzione')
      // ha il prodotto ma è disattivato
      else if (!this.productAlreadyOwned[1])
        this.toastr.warning("Sei in possesso di questo prodotto ma è stato disattivato. Contatta l'assistenza clienti.", 'Attenzione') // : this.toastr.warning('Sei già in possesso di questo prodotto.', 'Attenzione')
      this.loadingAction = false;
    } else {

      this.productChild = await this.productService.getProduct(childId)
      await this.productService.getProductChild(this.product.productId, childId)
        .then((_productChild: ProductChild) => {
          this.productChild.typePurchases = _productChild.typePurchases
          this.productChild.freeTrialDuration = _productChild.freeTrialDuration
          this.productChild.duration = _productChild.duration
          this.productChild.price = _productChild.priceTantum
        })

      // utente non ha il prodotto quindi aggiungo al carrello
      this.productService.addProductToBasket(this.productChild);
      // se utente è loggato aggiungo il prodotto nel DB
      if (this.currentUser && this.currentUser.jwtToken) {
        this.orderDetail.productId = this.productChild.productId
        this.orderDetail.typePurchases = this.productChild.typePurchases
        this.orderDetail.freeTrialDuration = this.productChild.freeTrialDuration
        this.orderDetail.duration = this.productChild.duration
        this.orderDetail.price = this.productChild.price
        await this.orderDetailService.createOrderDetail(this.orderDetail)
          .then(async () => {
            this.hasProductBeenAddedToBasket = true
            this.succeeded = true;
          }).catch(() => {
            this.hasProductBeenAddedToBasket = false
            this.succeeded = false;
            this.toastr.warning("Errore nell'aggiunta al carrello.", 'Attenzione')
          }).finally(() => {
            setTimeout(() => {
              this.loadingAction = false;
            }, 1000)
          })
      }
    }
  }

  /**
   * This function is called when the user clicks the `Buy Now` button. It takes the product information
   * and sends it to the Stripe API to start the checkout process
   * @returns The checkoutNoReg function returns a promise.
   */
  async startNoRegCheckout() {
    function makeProductName(data: string) {
      return data.replace(/ /g, '-').toLowerCase()
    }

    this.loadingAction = !this.loadingAction;

    this.stripeService.checkoutNoReg(this.product.stripePriceId, makeProductName(this.product.name))
  }

  /**
   * Controlla se il cliente possiede il prodotto e se questo prodotto è attivo.
   * @param productId - Il productId del prodotto da controllare.
   * @returns (Possiede prodotto: BOOLEAN, prodotto attivo: BOOLEAN)
   */
  async isProductAlreadyOwned(productId: number) {
    return await this.orderDetailService.doesOwnProduct(this.currentUser.accountId, "No", productId)
      .then(result => {
        return this.productAlreadyOwned = [result.item1, result.item2]
      })
  }

  /**
   * It checks if the user has already requested an indicator. If he hasn't, it shows a warning
   * message.
   */
  async checkAlreadyRequested() {

    await this.indicatorService.searchIndicators(this.currentUser.accountId, 0, 0,
      this.product.platformType, this.product.productId)
      .then(async (_requestedIndicators: SearchResult<Indicator>) => {
        // controllo che l'utente abbia il prodotto
        if (this.currentUser && this.currentUser.jwtToken) {
          await this.isProductAlreadyOwned(this.productId)
          // utente possiede il prodotto
          if (this.productAlreadyOwned[0]) {
            this.isAbleToRenew = false
            // ha il prodotto ed è attivo
            if (this.productAlreadyOwned[1])
              this.toastr.warning('Il pacchetto indicatori da te acquistato o richiesto non è ancora scaduto. Non potrai perciò rinnovarlo.', 'Attenzione')
            // ha il prodotto ma è disattivato
            else if (!this.productAlreadyOwned[1])
              this.toastr.warning("Sei in possesso di questo prodotto ma è stato disattivato. Contatta l'assistenza clienti.", 'Attenzione')
          } else {
            this.isAbleToRenew = true
            this.subscriptionService.subscriptionData.next()
          }
        }
        /* this.indicatorRequestMade = _requestedIndicators.totalCount
        const lastRequest: Indicator = _requestedIndicators.result[this.indicatorRequestMade - 1]
        if (!lastRequest.isActive)
          this.isAbleToRenew = true
        // altrimenti controllo che l'ultima richiesta fatta sia scaduta
        const lastPurchaseDate = new Date(lastRequest.dateOfPurchase);
        const expirationDate = lastPurchaseDate.setDate(lastPurchaseDate.getDate() + this.product.duration);

        // verifico se ci sono richieste scadute
        const requests: Indicator[] = _requestedIndicators.result
        const expiredRequest: Indicator[] = requests
          .filter(x => x.IndicatorPackageId !== this.product.productId && new Date() > new Date(expirationDate))
        // se ce ne sono setta la variabile a true in modo da renderizzare paypal
        if (expiredRequest.length > 0) {
          this.isAbleToRenew = true
          this.subscriptionService.subscriptionData.next()
        } else {
          // altrimenti stoppa e mostra all'utente un messaggio
          this.isAbleToRenew = false
          this.toastr.warning('Il pacchetto indicatori da te acquistato o richiesto non è ancora scaduto. Non potrai perciò rinnovarlo.', 'Attenzione')
        } */
      }).catch(() => {
        this.isAbleToRenew = false
        this.toastr.warning('Non potrai abbonarti a questo prodotto senza aver effettuato la prima richiesta, clicca su questo messaggio per visitare la pagina apposita.',
          'Attenzione', {
            timeOut: 5000
          })
          .onTap
          .pipe(take(1))
          .subscribe(() => this.router.navigate(['/dashboard/request-indicators']))
      })
  }

  showVideo = () => this.showIntroVideo = !this.showIntroVideo;

  videoShow = (_event: any) => this.showIntroVideo = !this.showIntroVideo;

  /* Using the `makeProductName` function to replace spaces with dashes and make the product name
  lowercase. Then it is using the `router.navigate` method to redirect to the checkout success (no-reg) page. */
  async redirectToConfirmation(params: Params) {
    function makeProductName(data: string) {
      return data.replace(' ', '-').toLowerCase()
    }

    let url = makeProductName(this.product.name)
    await this.router.navigate([`/shop/checkout/success-noreg/${url}`], {queryParams: params})
  }

  renderPaypal() {
    const checkoutUrl: string = 'CreateOrderNoReg'

    window.paypal.Buttons({
      style: {
        layout: 'horizontal',
        tagline: 'false',
        color: 'gold',
        shape: 'pill',
        height: 35
      },

      onInit: function (data, actions) {
        if (that.product == undefined)
          actions.disable()
      },

      async createOrder() {
        const res = await fetch(`${environment.apiUrl}/PayPal/${checkoutUrl}`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(that.product.payPalPrice)
        });
        console.info(res)
        const orderData = await res.json();
        console.info(orderData)
        that.paypalOrderId = orderData.id;
        return orderData.id;
      },

      onApprove: async function (data, actions) {
        const res = await fetch(`${environment.apiUrl}/PayPal/CaptureOrder`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(that.paypalOrderId)
        });

        const orderData = await res.json();
        const errorDetail = Array.isArray(orderData.details) && orderData.details[0];
        if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
          that.toastr.error('Il tuo metodo di pagamento è stato respinto', 'Pagamento respinto');
          return actions.restart(); // Recoverable state, per:

        }
        if (errorDetail) {
          return that.toastr.error(`Il tuo pagamento non è stato processato correttamente.`, 'Errore pagamento'); // Show a failure message (try to avoid alerts in production environments)
        }
        // Successful capture! For demo purposes:
        const transaction = orderData.purchase_units[0].payments.captures[0];
        let navigationExtras: NavigationExtras;
        let requestsMade: number;
        if (that.product.productType === ProductType.Indicatore && that.product.typeCovers === TypeCovers.Cover) {
          requestsMade = that.indicatorRequestMade
        } else {
          requestsMade = undefined
        }
        navigationExtras = {
          queryParams: {
            'isPaypal': 'paypal',
            'sessionId': that.payPalOrderId,
            'orderID': that.paypalOrderId,
            'tx_id': transaction.id,
            'tx_status': transaction.status,
            'amount': transaction.amount.value,
            'product_id': that.productId,
            'howManyRequests': requestsMade,
            'pyplGrId': that.product.productName === 'Previsionali' ? 'iA3Z9' : ''
          }
        };
        await that.redirectToConfirmation(navigationExtras.queryParams);
      }
    }).render(this.paypalDetailRef?.nativeElement)
  }
}
