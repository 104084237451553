<div
  class="flex flex-col justify-center min-h-screen w-full h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-orange-blob-dark bg-cover bg-no-repeat">
  <app-loading-animation *ngIf="loading"></app-loading-animation>
  <h1 *ngIf="myProduct != undefined" class="text-4xl pt-24 font-medium text-focus-in text-white">{{ myProduct.name }}
  </h1>
  <h3
    class="mx-auto text-white pt-2 cursor-pointer group-hover:text-redCustom transition duration-300 ease-in-out text-focus-in">
    <svg xmlns="http://www.w3.org/2000/svg"
         class="group-hover:text-redCustom transition duration-300 ease-in-out inline-flex pb-1 h-5 w-5" fill="none"
         viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
    </svg>
    <a class="group" (click)="back()">
      <span>Torna indietro</span>
    </a>
  </h3>
  <div class="flex w-full mx-auto overflow-hidden">
    <video class="m-auto w-full md:w-2/3 mt-4"
           [attr.title]="myProduct?.name"
           [attr.playsinline]="true"
           [attr.src]="videoSources?.length > 0 ? videoSources[0]?.src : null"
           controls>
    </video>
  </div>
</div>
