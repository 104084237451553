<div
  class="flex w-full h-full min-h-screen pt-10 px-4 md:px-6 pb-20 text-center bg-customDark sm:bg-orange-blob-dark sm:bg-cover sm:bg-no-repeat">
  <div class="flex translate-y-16 md:translate-y-8 md:m-auto w-full h-full">
    <div class="m-auto w-full md:w-3/4 xl:w-1/2">
      <div
        class="flex bg-customDark border rounded-lg overflow-visible border-gray-700 px-4 pt-5 pb-4 sm:p-10 sm:pb-4 w-full">
        <div class="flex flex-col">
          <div class="flex flex-col mx-auto w-full">
            <h1
              class="mb-6 underline underline-offset-4 decoration-redCustom py-3 text-center text-4xl text-bold text-white">
              Richiesta Indicatori</h1>
            <p class="mx-auto w-full text-sm pt-2 text-gray-300">
              <span> <span class="font-bold text-redCustom">ATTENZIONE:</span> In questa pagina è possibile richiedere gli indicatori per la tua piattaforma. Prima di procedere guarda attentamente uno dei video sottostanti.</span><br>
              <!-- <span *ngIf="indicator.platformType !== platformTypeEnum.MultiChartsNet" class="text-xs">N.B: Riceverai il file alla mail associata al tuo account.</span> -->
            </p>
            <span *ngIf="indicator.platformType === platformTypeEnum.MultiChartsNet" [@fade]="indicator"
                  class="mx-auto w-full text-xs text-gray-300 pt-2 transition duration-300 ease-out">
              <span class="font-bold text-redCustom">ATTENZIONE</span>: Se hai già ricevuto gli indicatori <span
              class="font-bold">MultiCharts .NET</span> via mail continua nella sezione sottostante.
            </span>
            <p *ngIf="indicator.platformType === platformTypeEnum.MultiChartsNet" [@fade]="indicator"
               class="max-w-lg mx-auto text-sm text-white mt-4">
              Se hai già effettuato un download da questo sito inserisci o modifica la tua licenza MultiCharts .NET
              cliccando su
              <span [routerLink]="['/dashboard', 'profile']" [state]="{ data: { modalShow: true }}"
                    class="text-redCustom hover:text-redCustom-light font-semibold cursor-pointer">
                Questo Link
              </span>
            </p>

            <div
              class="flex flex-row text-sm md:text-base text-center mx-auto mt-2 space-x-4 md:space-x-8 text-redCustom font-semibold">
              <div class="container flex flex-wrap mx-auto pt-2">
                <h1 class="mx-auto transition duration-200 ease-in-out">Video Istruzioni MultiCharts .NET</h1>
                <app-video-player
                  [dimensions]="['400', '225']"
                  [id]="multiChartsNETVideo.title.replaceAll(' ', '-').toLowerCase()"
                  [facadeId]="multiChartsNETVideo.id"
                  [vimeoLink]="multiChartsNETVideo.url"
                  class="w-full h-full">
                </app-video-player>
              </div>

              <div class="container flex flex-wrap mx-auto pt-2">
                <h1 class="mx-auto transition duration-200 ease-in-out">Video Istruzioni MultiCharts & Metatrader</h1>
                <app-video-player
                  [dimensions]="['400', '225']"
                  [id]="multiChartsMetatraderVideo.title.replaceAll(' ', '-').toLowerCase()"
                  [facadeId]="multiChartsMetatraderVideo.id"
                  [vimeoLink]="multiChartsMetatraderVideo.url"
                  class="w-full h-full">
                </app-video-player>
              </div>
            </div>
          </div>
          <div class="flex mx-auto">
            <form (ngSubmit)="onSubmit()" [formGroup]="form" autocomplete="off">
              <div class="flex flex-wrap -mx-1 py-4 overflow-hidden md:-mx-1 lg:-mx-1 xl:-mx-1">
                <!-- DOWNLOAD CONTRATTO -->
                <div class="flex flex-col md:flex-row w-full">
                  <div class="flex flex-col text-center md:text-left m-auto w-full">
                    <a [href]="contractUrl" class="text-white hover:text-redCustom transition duration-300 ease-in-out"
                       download rel="noreferrer" target="_blank">
                      <span class="pr-2 text-base font-bold">Scarica Contratto</span>
                      <svg class="inline-block mb-1 h-6 w-6" fill="none" stroke="currentColor"
                           viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
                          stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2"/>
                      </svg>
                    </a>
                    <label class="block text-xs font-medium text-gray-300 pt-1">
                      Clicca per scaricare il contratto da firmare
                    </label>
                  </div>
                  <!-- TIPOLOGIA -->
                  <div class="py-4 px-1 w-full mx-auto overflow-hidden">
                    <label class="label-std-left-validated">Tipologia</label>
                    <select (change)="makePlaceholder()" [(ngModel)]="indicator.platformType"
                            class="input-std-dark"
                            formControlName="platformType"
                            name="platformType">
                      <option
                        *ngFor="let item of platformType | enumToArray | filterPlatformType: platformType.MT5 | filterPlatformType: platformType.NinjaTrader7"
                        [ngValue]='item.index'>
                        <ng-container
                          *ngIf="item !== platformType.MT5 && item !== platformType.NinjaTrader7">{{ item.name }}
                        </ng-container>
                      </option>
                    </select>
                    <label *ngIf="!m.platformType.invalid" class="label-std-left">
                      Seleziona la tua piattaforma
                    </label>
                    <p *ngIf="m.platformType.invalid"
                       class="invalid-label">
                      Seleziona la tua piattaforma
                    </p>
                  </div>
                </div>
                <!-- SCELTA PRODOTTO -->
                <div class="my-1 py-4 w-1/2 overflow-hidden px-2">
                  <label class="label-std-left-validated">
                    Scegli Prodotto
                  </label>
                  <select (change)="selectProductId($event)" class="input-std-dark"
                          name="whichProduct">
                    <option value="Scegli Prodotto">Scegli Prodotto</option>
                    <option *ngFor="let product of myProducts" value="{{ product.productId }}">
                      {{ product.name }}
                    </option>
                  </select>
                  <label *ngIf="!m.productName.invalid" class="label-std-left">
                    Seleziona il prodotto
                  </label>
                  <p *ngIf="m.productName.invalid" class="invalid-label">
                    Seleziona il prodotto
                  </p>
                </div>
                <!-- ID LICENZA -->
                <div class="my-1 py-4 px-1 w-1/2 overflow-hidden md:my-1 mf:px-1">
                  <label class="label-std-left-validated">
                    ID Licenza
                  </label>
                  <input [(ngModel)]="indicator.license" [placeholder]="placeholder"
                         [value]="form.get('license').value?.trim()"
                         autocomplete="false"
                         class="input-std-dark"
                         formControlName="license" name="license"
                         type="text">
                  <label *ngIf="!m.license.touched && indicator.platformType !== platformTypeEnum.Scegli"
                         class="label-std-left">
                    Inserisci la licenza della tua piattaforma
                  </label>
                  <!--CUSTOM MESSAGE-->
                  <div *ngIf="m.license.invalid" class="invalid-label">
                    <div [ngSwitch]="indicator.platformType">
                      <p *ngSwitchCase="platformTypeEnum.MultiChartsNet">
                        La Licenza deve essere un numero, fai attenzione agli spazi.
                      </p>
                      <p *ngSwitchCase="platformTypeEnum.MultiCharts">
                        La Licenza deve iniziare con MC_ e continuare con soli numeri
                      </p>
                      <p *ngSwitchCase="platformTypeEnum.MT4 || platformTypeEnum.MT5">
                        Inserisci la licenza per continuare
                      </p>
                    </div>
                  </div>
                </div>
                <!-- DATA DI ACQUISTO -->
                <div class="flex flex-row justify-between space-x-4">
                  <div *ngIf="indicator.platformType !== platformTypeEnum.MultiChartsNet" [@fade]
                       class="flex pl-2 flex-col relative">
                    <label class="label-std-left-validated">
                      Data Acquisto
                    </label>
                    <div class="relative text-white">
                      <input [(ngModel)]="indicator.dateOfPurchase"
                             [max]="today.toISOString().split('T')[0]"
                             class="input-std-dark"
                             formControlName="dateOfPurchase"
                             style="color-scheme: dark;"
                             type="date">
                    </div>
                    <label *ngIf="!m.dateOfPurchase.invalid && !form.errors?.dateNotValid" class="label-std-left">
                      Inserisci la data di acquisto del tuo corso
                    </label>
                    <label *ngIf="m.dateOfPurchase.errors?.required" class="invalid-label">
                      Inserisci la data di acquisto del tuo corso
                    </label>
                    <p *ngIf="m.dateOfPurchase.errors?.dateNotValid" class="invalid-label">
                      Data non valida, deve essere minore di oggi
                    </p>
                  </div>
                  <!-- ID BROKER -->
                  <div *ngIf="metaTraderRequirements.includes(indicator.platformType)"
                       [@fade]
                       class="flex pl-2 flex-col relative">
                    <label class="label-std-left-validated">ID
                      Broker</label>
                    <input [(ngModel)]="indicator.brokerId" class="input-std-dark"
                           formControlName="brokerId" name="brokerId"
                           placeholder="1234567"
                           type="text">
                    <label *ngIf="!m.brokerId.invalid" class="label-std-left">
                      Inserisci il numero del conto Broker
                    </label>
                    <p *ngIf="m.brokerId.dirty && m.brokerId.invalid" class="invalid-label">
                      Questo parametro deve essere un numero
                    </p>
                  </div>
                </div>
                <!-- UPLOAD CONTRATTO -->
                <div class="flex pt-4 mx-auto w-full">
                  <div class="flex flex-col mx-auto">
                    <div class="text-white hover:text-redCustom transition duration-300 ease-in-out">
                      <input #file (change)="uploadFile(file.files)" accept=".pdf" formControlName="signedContract"
                             placeholder="Choose file"
                             style="display:none;"
                             type="file">
                      <button (click)="file.click()" type="button">
                        <span *ngIf="!message" class="px-2 text-base font-bold">Contratto Firmato</span>
                        <span *ngIf="progress == undefined && message == undefined">
                          <svg [ngClass]="{'animate-bounce': progress > 0 && progress < 100}"
                               class="inline-block h-6 w-6 mb-1"
                               fill="none" stroke="currentColor" viewBox="0 0 24 24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2"/>
                          </svg>
                        </span>
                      </button>
                      <label *ngIf="progress == undefined && message == undefined"
                             [ngClass]="{'invalid-label': m.signedContract.invalid}"
                             class="label-std-left-validated">
                        Clicca per caricare il contratto firmato
                      </label>
                    </div>
                    <div>
                      <span *ngIf="progress > 0 && progress < 100"
                            class="font-bold text-white text-sm text-focus-in-fast">
                        {{ progress }}%
                      </span>
                      <span *ngIf="message" class="font-bold text-greenCustom text-sm text-focus-in-fast">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col m-auto pt-10">
                  <div class="flex mx-auto">
                    <button
                      class="button-small disabled:opacity-25"
                      type="submit">
                      <div *ngIf="!loading" class="flex flex-row">
                        <svg class="m-auto h-4 w-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
                            stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2"/>
                        </svg>
                        <span class="text-sm">Invia Richiesta</span>
                      </div>
                      <div *ngIf="loading" class="flex flex-row">
                        <span class="text-sm">Carico</span>
                        <app-loading-animation-small class="inline-flex my-auto ml-2"></app-loading-animation-small>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="indicator.platformType === platformTypeEnum.MultiChartsNet" [@fade]
                       class="flex flex-col mx-auto py-4">
                    <span class="mx-auto text-gray-300 text-xs max-w-md mt-4">
                      Potrai scaricare i tuoi indicatori direttamente nella sezione
                      <a [routerLink]="['/dashboard', 'my-products']"
                         class="text-redCustom hover:text-redCustom-light font-semibold cursor-pointer">
                        I Miei Prodotti
                      </a> a richiesta completata.
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-indicator-instruction-video
  [showModal]="showVideoInstructionModal">
</app-indicator-instruction-video>
