import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout-canceled',
  templateUrl: './checkout-canceled.component.html',
  styleUrls: ['./checkout-canceled.component.scss']
})
export class CheckoutCanceledComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigateByUrl('/')
    }, 1000)
  }
}
