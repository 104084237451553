import { Component, Input } from '@angular/core';
import { ProductType } from '../../helpers/enums/productType.enum';
import { MyProduct } from '../../helpers/models/myProduct.model';
import { environment } from '../../../environments/environment';
import { ProductAvailability } from '../../helpers/enums/productAvailability.enum';
import { fade } from '../../helpers/animations/fade.animation';

@Component({
  selector: 'app-my-product-card',
  templateUrl: './my-product-card.component.html',
  styleUrls: ['./my-product-card.component.scss'],
  animations: [ fade ]
})
export class MyProductCardComponent {

  @Input() product: MyProduct

  productType = ProductType
  productAvailability = ProductAvailability
  serverUrl: string = environment.storageUrl;
  defaultImage: string = "https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/tt_logo.png"

  constructor() { }
}
