import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductType } from '@/helpers/enums/productType.enum';
import { IAuthenticateResponse } from '@/helpers/models/authenticate.model';
import { Product } from '@/helpers/models/product.model';
import { StripePaymentCheck } from '@/helpers/models/stripePaymentCheck.model';
import { AuthService } from '@services/auth.service';
import { OrderDetailService } from '@services/orderDetails.service';
import { ProductService } from '@services/products.service';
import { StripeTTAService } from '@services/stripeTTA.service';
import { environment } from '@envs/environment';
import { ComponentCanDeactivate } from "@/helpers/guards/pendingChanges.guard";
import { Observable } from "rxjs";
import { ActionRequired } from '@/helpers/enums/actionRequired.enum';
import { ProductStatus } from '@/helpers/enums/productStatus.enum';

@Component({
  selector: 'app-checkout-subscription-success',
  templateUrl: './checkout-subscription-success.component.html',
  styleUrls: ['./checkout-subscription-success.component.scss']
})
export class CheckoutSubscriptionSuccessComponent implements OnInit, ComponentCanDeactivate {

  serverUrl: string = environment.storageUrl;
  productId: number = 0
  parentId: number = 0
  product: Product
  productType = ProductType
  currentUser: IAuthenticateResponse
  orderId: number;

  loading: boolean = false;

  sessionId: string = ''

  constructor(
    private router: Router,
    private stripe: StripeTTAService,
    private toastr: ToastrService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private orderDetailService: OrderDetailService,
    private productService: ProductService) {
    this.route.queryParams.subscribe(param => {
      this.productId = param['productId']
      this.parentId = param['parentId']
      this.sessionId = param['session_id']
    })
    this.currentUser = this.auth.currentUserValue
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.loading;
  }

  async ngOnInit() {
    this.loading = true
    await this.productService.getProductMinimal(this.productId)
      .then(async (product: Product) => {
        this.product = product
        await this.stripe.getPaymentIntentStatus(this.sessionId)
          .then(async (paymentCheck: StripePaymentCheck) => {
            if (paymentCheck.paymentIntentStatus === 'trialing' || paymentCheck.paymentIntentStatus === 'active') {
              await this.orderDetailService.doesOwnProduct(this.currentUser.accountId, this.product.name, this.product.productId)
                .then(async (res) => {
                  switch (res) {
                    // ha il prodotto ma non è attivo
                    case res.item1 && !res.item2:
                      await this.orderDetailService.changeOrderDetailActivation(this.product.productId, 
                        this.product.actionRequired === ActionRequired.ContractSignature ? ProductStatus.Unsigned : ProductStatus.Active)
                        .then(() => {
                          this.productService.myProducts.next([])
                          this.toastr.success('Il tuo abbonamento è stato attivato con successo!', 'Abbonamento attivato!')
                          this.loading = false
                          this.router.navigateByUrl('/dashboard/my-products')
                        }).catch(() => {
                          this.toastr.warning("C'è stato un errore nel processare il tuo abbonamento, contatta il servizio clienti.", 'Attenzione')
                        }).finally(() => {
                          this.loading = false
                        })
                      break;
                    // non ha il prodotto e di conseguenza non è attivo
                    case !res.item1 && !res.item2:
                      /* this.orderDetailService.assignProductToNewAccount(this.product.productId, this.currentUser.accountId, paymentCheck.paymentIntentId, paymentCheck.amountTotal, false)
                        .then(() => {
                          this.productService.myProducts.next([])
                          this.toastr.success('Il tuo abbonamento è stato attivato con successo!', 'Abbonamento attivato!')
                          this.loading = false
                          this.router.navigateByUrl('/dashboard/my-products')
                        })
                        .catch(() => { */
                          this.toastr.warning("C'è stato un errore nel processare il tuo abbonamento, contatta il servizio clienti.", 'Attenzione')
                        /* }).finally(() => {
                        this.loading = false
                      }) */
                      break;
                    // nessuna delle due opzioni precedenti
                    default:
                      /* await this.getCurrentOrderId(this.currentUser.accountId) 
                      .then(async (_currentOrderId: number) => {
                        let order: Order = {
                          orderId: _currentOrderId,
                          accountId: this.currentUser.accountId,
                          // Add
                          externalTransactionId: paymentCheck.paymentIntentId,
                          amountPaid: paymentCheck.amountTotal
                        };
                        await this.orderService.updateOrder(order)
                      }); */

                      this.toastr.success('Il tuo abbonamento è stato attivato con successo!', 'Abbonamento attivato!')
                      this.productService.myProducts.next([])
                      this.loading = false
                      await this.router.navigateByUrl('/dashboard/my-products')
                      break;
                  }
                })
            }
          })
      })
  } 

/*   async getCurrentOrderId(accountId: string) {
    return await this.orderService.getCurrentOrderId(accountId)
      .then(res => {
        return this.orderId = res
      })
  } */
}
