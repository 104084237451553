import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyProduct } from '@models/myProduct.model';
import { ProductService } from '@services/products.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ProductType } from '@enums/productType.enum';

@Component({
  selector: 'app-instruction-video',
  templateUrl: './instruction-video.component.html',
  styleUrls: ['./instruction-video.component.scss']
})
export class InstructionVideoComponent implements OnInit {

  productId!: number;
  myProduct!: MyProduct;
  productType = ProductType;
  loading: boolean = false;

  videoSources: VideoSource[] = [
    {
      src: 'https://vimeo.com/76979871',
      provider: 'vimeo',
    },
  ];
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
    private route: ActivatedRoute,
    private productService: ProductService) {
    this.route.params.subscribe(params => {
      this.productId = parseInt(params['instructionVideoId'])
    })
  }

  async ngOnInit() {
    if (this.productId > 0 && this.productId != undefined) {
      await this.getMyProduct()
    }
  }

  back = () => this.location.back()

  async getMyProduct(){
    this.loading = true
    await this.productService.getMyProduct(this.productId)
      .then((_product: MyProduct) => {
        this.loading = false;
        if (_product.productType != this.productType.Indicatore) {
          this.router.navigateByUrl('/')
          throw new Error('');
        }
        if (_product.hasErrors)
          this.toastr.error("Errore nel caricamento del prodotto, riprova più tardi.", "Errore di caricamento")
        this.myProduct = _product
        this.videoSources[0].src = this.myProduct.vimeoLink
      })
  }
}

interface VideoSource {
  src: string; // URL del video
  provider: string; // Fornitore del video, es: 'vimeo'
}
