import {Component, EventEmitter, HostListener, OnInit, Output, ViewChild} from '@angular/core';
import {IndicatorService} from '@services/indicators.service';
import {PlatformType} from '@/helpers/enums/platformType.indicator';
import {Indicator} from '@models/indicator.model';
import {AuthService} from '@services/auth.service';
import {IAuthenticateResponse} from '@models/authenticate.model';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {environment} from '@envs/environment';
import {ToastrService} from 'ngx-toastr';
import {SEOService} from '@services/seo.service';
import {Router} from '@angular/router';
import {MyProduct} from '@models/myProduct.model';
import {ProductService} from '@services/products.service';
import {ProductType} from '@/helpers/enums/productType.enum';
import {SearchResult} from '@models/searchResult.model';
import {
  IndicatorInstructionVideoComponent
} from '@/helpers/modals/indicator-instruction-video/indicator-instruction-video.component';
import {fade} from '@/helpers/animations/fade.animation';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {
  MetTraderValidators,
  MultiChartsNETValidators,
  MultiChartsValidators
} from "@/helpers/patterns/validators.patterns";
import {Observable, Subscription} from "rxjs";
import {AutoUnsubscribe} from "ngx-auto-unsubscribe-decorator";
import {ComponentCanDeactivate} from "@/helpers/guards/pendingChanges.guard";
import {TypeCovers} from '@/helpers/enums/typeCovers.enum';
import {HelpVideo} from '@/helpers/models/helpVideo.model';

const defaultValues: Indicator = {
  accountId: '',
  indicatorId: 0,
  license: null,
  signedContract: '',
  platformType: PlatformType.Scegli,
  createdAt: new Date(), // productId: 0,
  productName: 'Scegli Prodotto',
  dateOfPurchase: null,//new Date(new Date().setDate(new Date().getDate() + 1)),
  brokerId: '',
  isChecked: false,
  isActive: true
}

@Component({
  selector: 'app-indicators-request',
  animations: [fade],
  templateUrl: './indicators-request.component.html',
  styleUrls: ['./indicators-request.component.scss']
})
export class IndicatorsRequestComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild(IndicatorInstructionVideoComponent) indicatorRequestInstructions: IndicatorInstructionVideoComponent
  @Output() public onUploadFinished = new EventEmitter();

  metaTraderRequirements: PlatformType[] = [PlatformType.MT4, PlatformType.MT5]

  public progress!: number;
  public message!: string;

  today: Date = new Date()
  platformTypeEnum = PlatformType;
  platformType = PlatformType;
  indicator: Indicator = defaultValues

  loading: boolean = false
  hasAlreadyMadeRequest: boolean = false;
  showVideoInstructionModal: boolean = false

  placeholder: string = ''
  contractFileName: string = ''

  myProducts: MyProduct[] = []

  currentUser: IAuthenticateResponse;
  contractUrl: string = 'https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/CONTRATTO-INDICATORI-1.pdf'

  @AutoUnsubscribe() productSubscription: Subscription
  form: UntypedFormGroup
  multiChartsNETVideo: HelpVideo = {
    title: 'Video Istruzioni MultiCharts .NET', url: 'https://player.vimeo.com/video/929798215', id: '677734117'
  }
  multiChartsMetatraderVideo: HelpVideo = {
    title: 'Video Istruzioni MultiCharts & Metatrader', url: 'https://player.vimeo.com/video/677751407', id: '677751407'
  }

  constructor(private formBuilder: UntypedFormBuilder, private productService: ProductService, private router: Router, private seo: SEOService, private toastr: ToastrService, private authService: AuthService, private http: HttpClient, private indicatorService: IndicatorService) {
    this.seo.updateTitle('Richiesta Indicatori | Top-Trading Academy')
    this.currentUser = this.authService.currentUserValue
    this.indicator = defaultValues
    this.contractFileName = `Contratto Indicatori ${this.currentUser.firstName}`
    this.indicator.accountId = this.currentUser.accountId
    this.form = this.formBuilder.group({
      productName: new UntypedFormControl(this.indicator.productName, [(c) => {
        if (c.value == 'Scegli Prodotto') {
          return {isInitialValue: true}
        }
        return null
      }]),
      platformType: new UntypedFormControl(this.indicator.platformType, [(c) => {
        if (c.value === PlatformType.Scegli) {
          return {isInitialValue: true}
        }
        return null
      }]),
      license: new UntypedFormControl(this.indicator.license, [Validators.required]),
      signedContract: new UntypedFormControl(this.indicator.signedContract, [Validators.required]),
      dateOfPurchase: new UntypedFormControl(this.indicator.dateOfPurchase),
      brokerId: new UntypedFormControl(this.indicator.brokerId)
    })
  }

  get m() {
    return this.form.controls
  }

  @HostListener('window:beforeunload') canDeactivate(): Observable<boolean> | boolean {
    if (this.form.dirty) {
      return this.form.valid
    } else {
      return true
    }
  }

  async ngOnInit() {
    //TODO togliere mock
    /* this.setMockContract()*/
    this.setPlatformTypeValidators()
    try {
      const val = await this.productService.searchMyProductsForIndicatorRequests();
      this.myProducts = val.result;
    } catch (error) {
      this.toastr.warning("Non hai un prodotto per il quale richiedere gli indicatori", "Attenzione");
    }
  }

  /**
   * Usato per simulare il caricamento del contratto
   * Setta il valore nel form in modo che non venga considerato
   * come non messo
   */
  setMockContract() {
    this.form.controls['signedContract'].setValidators(null)
  }

  /**
   * Cambia dinamicamente i validatori e gli errori
   * in base alla piattaforma scelta (piattaforme hanno
   * esigenze diverse) o alla data di acquisto (necessaria
   * per tutte le piattaforme tranne che MultiCharts .NET
   */
  setPlatformTypeValidators() {
    this.form?.get('platformType').valueChanges.subscribe(val => {
      this.form.controls['license'].setValue(null)
      switch (val) {
        case PlatformType.MultiCharts:
          this.form.controls['dateOfPurchase'].setValidators([Validators.required, Validators.minLength(10)])
          this.form.controls['license'].setValidators(MultiChartsValidators)
          this.form.controls['brokerId'].setValidators(null)
          break
        case PlatformType.MultiChartsNet:
          this.form.controls['dateOfPurchase'].setErrors(null)
          this.form.controls['dateOfPurchase'].setValidators(null)
          this.form.controls['license'].setValidators(MultiChartsNETValidators)
          this.form.controls['brokerId'].setValidators(null)
          break
        case PlatformType.MT4:
          this.form.controls['dateOfPurchase'].setValidators([Validators.required, Validators.minLength(10)])
          this.form.controls['brokerId'].setValidators(MetTraderValidators)
          this.form.controls['license'].setValidators([Validators.required, Validators.minLength(5)])
          break
        case PlatformType.MT5:
          this.form.controls['dateOfPurchase'].setValidators([Validators.required, Validators.minLength(10)])
          this.form.controls['brokerId'].setValidators(MetTraderValidators)
          this.form.controls['license'].setValidators([Validators.required, Validators.minLength(5)])
          break
        case PlatformType.NinjaTrader8:
          this.form.controls['dateOfPurchase'].setErrors(null)
          this.form.controls['dateOfPurchase'].setValidators(null)
          //this.form.controls['license'].setValidators(MultiChartsNETValidators)
          this.form.controls['brokerId'].setValidators(null)
          break
      }
    })

    this.form?.get('dateOfPurchase').valueChanges.subscribe(val => {
      let currentDate = new Date(val).setHours(0, 0, 0, 0,)
      let today = new Date().setHours(0, 0, 0, 0,)
      if (val == null || currentDate == today) {
        this.form.controls['dateOfPurchase'].setErrors({dateNotValid: true})
      }
    })
  }

  /**
   * Manda la richiesta indicator alle API.
   * in caso la richiesta sia per MultiCharts .NET viene mandata la richiesta
   * e, in caso vada a buon fine, viene aggiornato il profilo utente con la licenza
   * inserita, qual'ora la richiesta andasse storta viene mostrato un messaggio di
   * errore all'utente.
   * In caso la richiesta NON sia per MultiCharts .NET viene mandata una richiesta
   * indicatori che salva i dati nel DB e comunica via mail i dati necessari per la
   * creazione manuale del file degli indicatori, che sarà successivamente inviato via
   * mail (fuori dalla logica di sistema). In caso la richiesta non vada a buon fine
   * viene mostrato un messaggio di errore per l'utente.
   */
  async onSubmit() {
    if (this.form.valid) {
      this.loading = true;
      if (this.indicator.platformType === PlatformType.MultiChartsNet || this.indicator.platformType === PlatformType.NinjaTrader8) {
        if (this.hasAlreadyMadeRequest === false) {
          let product = this.myProducts.find(x => x.productId === this.indicator.productId)

          if (product.productType === ProductType.Indicatore && product.typeCovers === TypeCovers.Cover) {
            this.indicator.dateOfPurchase = this.indicator.createdAt
            this.indicator.brokerId = "RICHIESTA_INDICATORE_SINGOLO"
            this.indicator.IndicatorPackageId = product.productId
          }
          await this.indicatorService.addIndicatorsRequest(defaultValues)
            .then(res => {
              this.toastr.success(res.message, "Richiesta Inviata")
              this.currentUser.multichartsNetLicenses = defaultValues.license
              this.authService.updateDetails(this.currentUser)
              this.productService.myProducts.next([])
              this.router.navigate(['/dashboard/my-products'])
            }).catch(res => {
              this.toastr.warning(res.error, "Attenzione")
            }).finally(() => {
              this.loading = false
            })
        }
      } else {
        await this.indicatorService.createIndicator(defaultValues)
          .then(res => {
            if (res.hasErrors) {
              this.toastr.error("Errore nell'invio della richiesta", "Errore")
            } else {
              this.toastr.success("Richiesta inviata con successo, riceverai una mail con il file richiesto non appena gli indicatori saranno pronti", 'Richiesta inviata')
              this.router.navigate(['/'])
            }
          }).catch(() => {
            this.toastr.warning("C'è stato un errore nell'elaborazione della richiesta", "Attenzione")
          }).finally(() => {
            this.loading = false
          })
      }
    } else {
      console.log(this.form.controls)
      this.toastr.warning(`Inserisci i parametri necessari`, "Attenzione")
    }
  }

  /**
   * Effettua il caricamento del contratto sullo storage Azure facendo
   * una chiamata alle API, a caricamento avvenuto viene mostrato un messaggio
   * per l'utente e viene settato lo url del contratto nella richiesta e nel form
   * viene settata una stringa vuota (usare una stringa vuota evita l'errore su
   * HTMLInputElement, per il quale non si può settare direttamente un valore, deve essere fatto
   * tramite file) in modo che passi la validazione
   * @param files Il contratto in formato *.pdf
   */
  uploadFile = (files: any) => {
    if (files.length === 0) return;

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, `Contratto ${this.currentUser.email} ${new Date().toISOString()}.pdf`);

    let urlCall = `${environment.apiUrl}/Upload/CloudUpload/`
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1" || location.hostname === "shop-stage.top-tradingacademy.com") {
      urlCall += 'contratti-firmati-debug'
    } else {
      urlCall += 'contratti-firmati'
    }
    this.http.post(urlCall, formData, {
      reportProgress: true, observe: 'events'
    }).subscribe((event: any) => {
      let that = this
      if (event.type === HttpEventType.UploadProgress) this.progress = Math.round(100 * event.loaded / event.total); else if (event.type === HttpEventType.Response) {
        this.message = 'Caricato con successo';
        let url: string = JSON.stringify(event.body.fileName)
        this.indicator.signedContract = url.substring(1, url.length - 1)
        this.onUploadFinished.emit(event.body.filename);
        //console.log('url contratto', url)
        that.form.controls['signedContract']?.setValue("caricato")
        that.form.controls['signedContract'].setValidators(null)
        //console.log(this.form.controls)
      }
    })
  }

  makePlaceholder() {
    switch (this.indicator.platformType) {
      case PlatformType.MT4:
        this.placeholder = "Esempio: ......"
        break;
      case PlatformType.MT5:
        this.placeholder = "Esempio: ......"
        break;
      case PlatformType.MultiCharts:
        this.placeholder = "Esempio: MC_3419502"
        break;
      case PlatformType.MultiChartsNet:
        this.placeholder = "Esempio: 3419502129"
        break;
    }
  }

  async selectProductId(event: any) {
    if (event.target.value !== 'Scegli Prodotto') {
      this.indicator.productId = Number(event.target.value)
      this.indicator.productName = this.myProducts.find(x => x.productId === this.indicator.productId).name
      this.m.productName.setValue(this.indicator.productName)
      if (this.indicator.platformType === PlatformType.MultiChartsNet) {
        await this.searchIndicators()
      }
    } else this.m.productName.setValue(event.target.value);

    /*     const productName = event.target.value
        this.indicator.productName = productName
        this.m.productName.setValue(productName)
        if (productName != 'Scegli Prodotto') {
          this.indicator.productId = this.myProducts.find(x => x.name === productName).productId
          //this.indicator.productId = productId
          if (this.indicator.platformType === PlatformType.MultiChartsNet) {
            await this.searchIndicators()
          }
        }  */
  }

  async searchIndicators() {
    return await this.indicatorService.searchIndicators(this.currentUser.accountId, 0, 0, this.indicator.platformType, this.indicator.productId)
      .then((result: SearchResult<Indicator>) => {
        // controllo se ci sono richieste
        if (result.totalCount > 0) {
          // controlla se sono state fatte precedentemente o con il nuovo metodo
          let requestsMade = result.result.filter(x => (x.platformType === PlatformType.MultiChartsNet || x.platformType === PlatformType.NinjaTrader8) && x.indicatorPackageId !== null)
          // se sono state fatte con il nuovo metodo mostra un messaggio per l'utente
          if (requestsMade.length > 0) {
            this.toastr.warning("Hai già effettuato una richiesta per questo corso, se è scaduta dovrai procedere al rinnovo tramite lo Shop", "Attenzione")
            this.hasAlreadyMadeRequest = true;
          }
          return this.hasAlreadyMadeRequest
        } else {
          return this.hasAlreadyMadeRequest = false;
        }
      }).catch(() => {
        return this.hasAlreadyMadeRequest = false;
      })
  }

  async openInstructionModal(videoTitle: string, video: string) {
    await this.indicatorRequestInstructions.openModal(videoTitle, video)
  }

  toggleModal() {
    this.showVideoInstructionModal = !this.showVideoInstructionModal;
  }
}
