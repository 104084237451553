import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fade } from "@animations/fade.animation";

@Component({
  selector: 'app-indicator-instruction-video',
  templateUrl: './indicator-instruction-video.component.html',
  styleUrls: ['./indicator-instruction-video.component.scss'],
  animations: [fade]
})
export class IndicatorInstructionVideoComponent implements OnInit {

  @Input() showModal: boolean = false;
  @Output() modalCallback: EventEmitter<boolean> = new EventEmitter();

  videoUrl: string = '';
  videoTitle: string = '';

  constructor() {}

  ngOnInit() {
    this.videoUrl = 'https://vimeo.com/76979871';
  }

  async openModal(videoTitle: string, videoUrl: string) {
    this.videoTitle = videoTitle;
    this.showModal = true;
    this.videoUrl = videoUrl;
  }

  toggleModal(event: any) {
    this.showModal = false;
    this.modalCallback.emit(this.showModal); // Facoltativo, per notificare il genitore
  }
}
