import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@/helpers/services/auth.service';
import { Role } from '@/helpers/enums/role.enum';

export const adminGuard = async (route: any, state: any): Promise<boolean> => {
  const router = inject(Router); // Inietta Router
  const auth = inject(AuthService); // Inietta AuthService

  const currentUser = auth.currentUserValue;

  if (currentUser && currentUser.jwtToken) {
    const role: Role = currentUser.role;
    if (role === Role.Admin || role === Role.SuperAdmin) {
      // Consentire l'accesso per Admin e SuperAdmin
      return true;
    }
  }

  // Redirigere se non autorizzato
  await router.navigate(['/']);
  return false;
};
