<div
  class="flex justify-center min-h-screen w-full h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-customDark">
  <app-loading-animation *ngIf="loading"></app-loading-animation>
  <div *ngIf="products && products.length == 0 && !loading" class="flex h-screen mx-auto text-focus-in">
    <div class="flex flex-col m-auto">
      <h3 class="m-auto text-3xl font-bold text-white">Non hai acquistato nessun prodotto...</h3>
      <a [routerLink]="['/shop']">
        <h3
          class="m-auto text-xl font-medium pt-2 text-white transition-std transform hover:text-redCustom">
          Vai allo Shop</h3>
      </a>
    </div>
  </div>
  <div *ngIf="products && products.length > 0" class="container md:px-8 py-6 mx-auto">
    <h1 class="title-main text-focus-in-fast">
      I Prodotti di
      <span>{{ currentUser.firstName }}</span>
    </h1>
    <p class="mx-auto text-gray-400 mt-4 text-focus-in-fast">
      Visualizza i prodotti da te acquistati
    </p>
    <div class="flex flex-wrap justify-center">
      <div *ngFor="let type of availableTypes" class="flex flex-col justify-center">
        <div>
          <app-my-product-platform [products]="products | filterProductArray:type" [type]="type"></app-my-product-platform>
        </div>
      </div>
    </div>
  </div>
</div>
