import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "@/helpers/services/auth.service";
import {environment} from "@envs/environment";
import * as signalR from '@microsoft/signalr';
import {AmmiratoreMessage} from "@/helpers/models/ammiratoreMessage.model";
import {SignalAmmiratore} from "@/helpers/models/signalAmmiratore.model";
import {Operation} from "@/helpers/enums/operation.enum";
import {ToastrService} from "ngx-toastr";

@Injectable({providedIn: 'root'})
export class AmmiratoreLiveService implements OnInit {

  public ammiratoreHubConnection: signalR.HubConnection;
  public ammiratoreHubStatus: number = 0;
  public ammiratoreStatus = new Subject();
  public lastSignalSubject: BehaviorSubject<AmmiratoreMessage>;
  public ammiratoreNotificationSubject = new Subject<AmmiratoreMessage>();
  public ammiratoreSubscriberSubject: BehaviorSubject<boolean>;
  public openPositions: BehaviorSubject<SignalAmmiratore[]>

  closingOperations: Operation[] = [Operation.Close, Operation.TakeProfitClose, Operation.StopLossClose]

  constructor(private toastr: ToastrService, private http: HttpClient, private auth: AuthService) {
    this.lastSignalSubject = new BehaviorSubject<AmmiratoreMessage>(null)
    this.openPositions = new BehaviorSubject<SignalAmmiratore[]>([])
    this.ammiratoreSubscriberSubject = new BehaviorSubject<boolean>(false)
  }

  public ngOnInit() {
    this.ammiratoreStatus.subscribe((value: number) => this.ammiratoreHubStatus = value);
    this.auth.currentUserSubject.subscribe(user => {
      if (user === null) {
        this.openPositions.next([])
        this.ammiratoreHubStatus = 0
      }
    })
  }


  public async ConnectHub() {
    let that = this

    if (this.ammiratoreHubStatus === 0) {
      this.ammiratoreHubStatus = -1
      this.ammiratoreStatus.next(-1);

      await fetch(environment.functionAmmiratoreUrl + '/api/negotiate', {
        method: 'POST', mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: this.getHeaders()
      }).then(async (response) => response.json())
        .then(async (data: any) => {
          const options = {
            accessTokenFactory: () => data.accessToken
          }
          this.ammiratoreHubConnection = new signalR.HubConnectionBuilder()
            .withUrl(data.url, options)
            .build();

          this.ammiratoreHubConnection.start()
            .then(() => {
              this.ammiratoreHubStatus = 1;
              this.ammiratoreStatus.next(1);
              this.toastr.success("Connessione ad Ammiratore Live effettuata!")
            })
            .catch(err => {
              console.log('errore signalr', err);
              this.ammiratoreHubStatus = 0;
              this.ammiratoreStatus.next(0);
              setTimeout(() => {
                that.ConnectHub();
              }, 5000);
            });

          // Reconnect after 3 sec.
          this.ammiratoreHubConnection.onclose(() => {
            this.ammiratoreHubStatus = 0;
            this.ammiratoreStatus.next(0);
            setTimeout(() => {
              that.ConnectHub();
            }, 5000);
          });

          // react to new ammiratore signal
          this.ammiratoreHubConnection.on('newSignal', (signal: AmmiratoreMessage) => {

            this.ammiratoreNotificationSubject.next(signal);
            this.lastSignalSubject.next(signal)

            // TODO: https://top-tradingacademy.net/ è il nuovo indirizzo del sito.
            let senderName: string
            if (location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === 'shop-stage.top-tradingacademy.com') {
              senderName = 'DEBUG'
            } else {
              senderName = 'Marco'
            }
            if (signal.sender === senderName) {
              // se è un'operazione di chiusura
              // rimuovi la posizione dalla lista
              if (this.closingOperations.includes(signal.operation)) {
                // qualsiasi segnale di chiusura arrivi, all'utente live mostro una chiusura semplice
                // non una TakeProfit o una StopLoss
                signal.operation = Operation.Close
                this.openPositions.next(this.openPositions.value.filter(position => position.positionId !== signal.positionId))
                return
              }

              // se è una Partial close, modifica la posizione aperta per mostrare la nuova quantità
              if (signal.operation === Operation.PartialClose) {
                let openPosition: SignalAmmiratore = this.openPositions.value
                  .find(position => position.positionId === signal.positionId)

                let positionIndex: number = this.openPositions.value.indexOf(openPosition)

                let newContracts = Number(openPosition.contracts) - Number(signal.contracts.match(/\d+/)[0])
                // se non ci sono più partial close da fare togli la posizione dalla lista
                if (newContracts === 0) {
                  this.openPositions.next(this.openPositions.value.filter(position => position.positionId !== signal.positionId))
                }
                openPosition.contracts = newContracts.toString()

                let positions: SignalAmmiratore[] = this.openPositions.value
                positions[positionIndex] = openPosition
                this.openPositions.next(positions)
                return;
              }

              // altrimenti aggiungi la nuova operazione alla lista delle posizioni
              let positions: SignalAmmiratore[] = this.openPositions.value
              positions.unshift(signal)
              this.openPositions.next(positions)
            }
          })
        })
        .catch(() => {
          this.ammiratoreHubStatus = 0;
          this.ammiratoreStatus.next(0);
          setTimeout(() => {
            that.ConnectHub();
          }, 5000);
        })
    }
  }

  getHeaders() {
    return {
      'credentials': 'same-origin', 'x-ms-signalr-userid': this.auth.currentUserValue.accountId
    }
  }
}
