import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContactsComponent} from './components/contacts/contacts.component';
import {HelpComponent} from './components/help/help.component';
import {TermsConditionsComponent} from './components/terms-conditions/terms-conditions.component';
import {authGuard} from './helpers/guards/auth.guard';
import {HomeComponent} from './pages/home/home.component';
import {adminGuard} from "@/helpers/guards/admin.guard";
import {DisclaimerComponent} from "@/components/disclaimer/disclaimer.component";
import {VideoFrameComponent} from './pages/video-frame/video-frame.component';

const routes: Routes = [
  {path: '', component: HomeComponent, data: {title: 'Home'}},
  {
    path: 'dashboard',
    canActivate: [authGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module')
      .then(m => m.DashboardModule), data: {title: 'Dashboard'}
  },
  {
    path: 'shop',
    loadChildren: () => import('./pages/products/products.module')
      .then(m => m.ProductsModule), data: {title: 'Shop'}
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module')
      .then(m => m.AccountModule), data: {title: 'Account'}
  },
  {
    path: 'king-of-trading',
    canActivate: [adminGuard],
    loadChildren: () => import('./pages/king-of-trading/king-of-trading.module')
      .then(m => m.KingOfTradingModule), data: {title: 'King of Trading'}
  },
  {
    path: 'ammiratore',
    loadChildren: () => import('./pages/ammiratore/ammiratore.module')
      .then(m => m.AmmiratoreModule), data: {title: 'Ammiratore'}
  },
  {path: 'help', component: HelpComponent, data: {title: 'Aiuto'}},
  {path: 'termini-condizioni', component: TermsConditionsComponent, data: {title: 'Termini e condizioni'}},
  {path: 'disclaimer', component: DisclaimerComponent, data: {title: 'Disclaimer'}},
  {path: 'contacts', component: ContactsComponent, data: {title: 'Contatti'}},
  {path: 'video', component: VideoFrameComponent, data: {standalone: true, title: 'Video'}},
  {path: '**', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabledBlocking',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0], // [x, y]
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
