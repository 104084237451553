import { HttpClient } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import { PaymentType } from "../enums/paymentType.enum";

@Injectable({providedIn: 'root'})
export class HubSpotService {

  constructor(private http: HttpClient) {
  }

  hubSpotConfirmPurchase(email: string, productName: string, price: number, paymentType: PaymentType): Promise<number> {
    return this.http.post<number>(`${environment.apiUrl}/HubSpot/HubSpotConfirmPurchase`, {
      email: email, 
      productName: productName, 
      price: price,
      paymentType: paymentType
    })
    .toPromise();
  }
}