<div class="relative text-gray-300 flex flex-col bg-green-blob-dark bg-cover bg-no-repeat align-middle h-screen">
  <div class="flex flex-col m-auto px-4 md:px-0 w-full text-center">
    <h1 class="mx-auto text-3xl font-bold text-white">Pagamento avvenuto con successo!</h1>
    <div *ngIf="product" class="flex flex-col  m-auto text-center py-4">
      <app-product-card
        [product]="product"
        [purchased]="true">
      </app-product-card>
    </div>
    <h2 class="mx-auto text-lg text-center mt-1 text-white">
      Stiamo processando il tuo ordine, riceverai una conferma tra qualche instante.
    </h2>
    <p>
      <span class="text-redCustom font-semibold">
        Attenzione</span>: Non lasciare questa pagina mentre il processo è in corso.
    </p>
    <div *ngIf="loading" class="flex flex-row mx-auto mt-4">
      <img class="animate-bounce"
           src="../../../assets/images/tt_logo.png"
           alt="TT Logo"
           width="50" height="auto">
      <p class="text-lg">Carico...</p>
    </div>
  </div>
</div>
