import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { OrderDetailService } from '@/helpers/services/orderDetails.service';
import { fade } from '@/helpers/animations/fade.animation';
import { KindOfClient, SocialNetworks, SocialOutlets } from '@/helpers/enums/registration.enum';
import { IAuthenticateResponse } from '@/helpers/models/authenticate.model';
import { AuthService } from '@/helpers/services/auth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe-decorator";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import {
  CodiceUnivocoValidators, DiscordPattern,
  EmailPattern,
  FiscalCodeValidators,
  passwordMatching,
  PhonePattern,
  PostalCodeValidators,
  VatNumberValidators
} from "@/helpers/patterns/validators.patterns";
import { DiscordLoginResponse } from "@/helpers/modals/discordLoginResponse.model";

@Component({
  selector: 'app-confirm-account',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss'],
  animations: [fade]
})
export class ConfirmAccountComponent implements OnInit {

  @Input() confirmShow: boolean = false;
  @Output() modalCallback: EventEmitter<any> = new EventEmitter()

  currentUser: IAuthenticateResponse;
  @AutoUnsubscribe()
  currentUserSubscription: Subscription
  socialOutlets = SocialOutlets;
  socialNetworks = SocialNetworks;
  kindOfClient = KindOfClient;

  loading: boolean = false;
  succeeded: boolean = false;
  shouldAddDiscordAccount: boolean = false

  contactHours = ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']

  fromHour: string = ''
  toHour: string = ''

  form: UntypedFormGroup;

  constructor(
    private cd: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private orderDetailService: OrderDetailService,
    private toastr: ToastrService,
    public auth: AuthService) {
    this.currentUserSubscription = this.auth.currentUser.subscribe(user => {
      this.currentUser = user
      this.hoursRangeDeconstructor(this.currentUser?.preferredContactHours)
    })
    if (this.currentUser != null) {
      this.setDefaultValues()
      this.form = this.formBuilder.group({
        kindOfClient: new UntypedFormControl(this.currentUser.kindOfClient, [Validators.required]),
        firstName: new UntypedFormControl(this.currentUser.firstName, [Validators.required]),
        lastName: new UntypedFormControl(this.currentUser.lastName, [Validators.required]),
        businessName: new UntypedFormControl(this.currentUser.businessName),
        street: new UntypedFormControl(this.currentUser.street, [Validators.required]),
        city: new UntypedFormControl(this.currentUser.city, [Validators.required]),
        state: new UntypedFormControl(this.currentUser.state, [Validators.required]),
        postalCode: new UntypedFormControl(this.currentUser.postalCode, PostalCodeValidators),
        country: new UntypedFormControl(this.currentUser.country, [Validators.required]),
        phone: new UntypedFormControl(this.currentUser.phone, [Validators.required, Validators.pattern(PhonePattern)]),
        fiscalCode: new UntypedFormControl(this.currentUser.fiscalCode, FiscalCodeValidators),
        vatNumber: new UntypedFormControl(this.currentUser.vatNumber),
        pec: new UntypedFormControl(this.currentUser.pec),
        codiceUnivoco: new UntypedFormControl(this.currentUser.codiceUnivoco),
        mostUsedSocial: new UntypedFormControl(this.currentUser.mostUsedSocial, [Validators.required]),
        mostUsedSocialOutlets: new UntypedFormControl(this.currentUser.mostUsedSocialOutlets, [Validators.required]),
        acceptTerms: new UntypedFormControl(true, [Validators.requiredTrue]),
        disclaimer: new UntypedFormControl(this.currentUser.disclaimer, [Validators.requiredTrue]),
        privacyTrattamento: new UntypedFormControl(this.currentUser.privacyTrattamento, [Validators.requiredTrue]),
        privacyContatto: new UntypedFormControl(this.currentUser.privacyContatto, [Validators.required]),
        privacyThirdParty: new UntypedFormControl(this.currentUser.privacyThirdParty, [Validators.required]),
      }, {validator: passwordMatching})
    }
  }

  get m() {
    return this.form.controls
  }

  ngOnInit(): void {
    this.currentUserSubscription.add(
      this.auth.shouldConfirmAccount.subscribe(value => {
        if (value) {
          let updated: boolean = false
          if (!updated) {
            updated = true
            this.toastr.info('Completa il tuo account per continuare la navigazione', "Attenzione")
          }
        }
      })
    )

    this.auth.shouldAddDiscordAccount.subscribe(async shouldDoIt => {
      this.shouldAddDiscordAccount = shouldDoIt
    })
    //this.checkShouldAddDiscordAccount()
    this.setValidators()
  }

  setDefaultValues() {
    this.currentUser.acceptTerms = true
    this.currentUser.disclaimer = true
    this.currentUser.kindOfClient = this.kindOfClient.Privato
    this.currentUser.preferredContactHours = '8-20'
    this.currentUser.mostUsedSocial = this.socialNetworks.Facebook
    this.currentUser.mostUsedSocialOutlets = this.socialOutlets.WhatsApp
    this.currentUser.privacyTrattamento = true
    this.currentUser.privacyContatto = true
    this.currentUser.privacyThirdParty = true
  }

  setValidators() {
    this.form?.get('kindOfClient').valueChanges.subscribe(val => {
      if (val === KindOfClient.Impresa) {
        this.form.controls['businessName'].setValidators([Validators.required])
        this.form.controls['vatNumber'].setValidators(VatNumberValidators)
        this.form.controls['pec'].setValidators([Validators.required, Validators.pattern(EmailPattern)])
        this.form.controls['codiceUnivoco'].setValidators(CodiceUnivocoValidators)
      } else {
        this.form.controls['businessName'].setValidators(null)
        this.form.controls['vatNumber'].setValidators(null)
        this.form.controls['pec'].setValidators(null)
        this.form.controls['codiceUnivoco'].setValidators(null)
      }
    })
  }

  toggleModal = (event: any) => this.modalCallback.emit(this.confirmShow)

  hoursRangeBuilder() {
    this.currentUser.preferredContactHours = `${this.fromHour}-${this.toHour}`
  }

  hoursRangeDeconstructor(hours: string) {
    if (hours != undefined) {
      this.fromHour = hours.split('-')[0]
      this.toHour = hours.split('-')[1]
    }
  }

  async onSubmit() {
    if (this.form.valid) {
      this.loading = true
      await this.auth.updateAccount(this.currentUser)
        .then(async (user: any) => {
          this.succeeded = true
          this.loading = false
          this.updateSingleItems(user)
          this.auth.shouldConfirmAccount.next(false)
          this.toastr.success("Grazie mille per le informazioni fornite", "Grazie!")
        })
        .catch(() => {
          this.toastr.warning("Errore nell'aggiornamento dei dati, riprova o contatta il servizio clienti", "Attenzione")
          this.succeeded = false
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })

      await this.activateProduct()
        .then(async (activationResult: number) => {
          // prodotto attivato con successo
          // TODO verificare comportamento del seguente if
          /*if (activationResult === 1) {
            this.toastr.success(`Hai completato il tuo account, potrai adesso visualizzare il prodotto da te acquistato.`, 'Perfetto!')
            await this.router.navigateByUrl('dashboard/my-products')
          }*/
          // account completato ma prodotto attivato dopo ricevimento bonifico
          if (activationResult === 2) {
            this.toastr.success("Hai completato il tuo account ma il tuo pagamento è in attesa, riceverai una mail quando verrà finalizzato.", "Perfetto!")
            //await this.router.navigateByUrl('/')
          }
          /*if (this.currentUser.jwtToken && !DiscordPattern.test(this.currentUser.username)) {
            setTimeout(() => {
              this.auth.shouldShowPendingDiscordRedirection.next(true)
            }, 1000)
          }*/
        })
        .catch(() => {
          this.toastr.warning("Hai completato il tuo account ma c'è stato un errore nell'attivazione dei prodotti da te acquistati, per favore contatta il servizio clienti.", "Attenzione")
        })
        .finally(() => {
          this.loading = false
          this.toggleModal(null)
        })
    } else {
      this.getInvalidInputs(this.form)
      this.succeeded = false;
      console.log(this.form.controls)
      this.toastr.warning("Tutti i campi sono obbligatori. In rosso sono evidenziati i campi non validi", "Attenzione")
    }
  }

  hoursValidator() {
    if (Number(this.fromHour) > Number(this.toHour)) this.fromHour = this.toHour
  }

  getInvalidInputs(form: UntypedFormGroup) {
    for (const key of Object.keys(form.controls)) {
      if (form.controls[key].invalid) {
        let invalidControl = document.querySelector('[formcontrolname="' + key + '"]')
        invalidControl.classList.remove('input-std-dark')
        invalidControl.classList.add('input-std-invalid')
      }
    }
  }

  /**
   * Attiva i prodotti acquistati dall'utente che richiedevano un completamento dell'account.
   * Risultati possibili:
   *  - 1: prodotti presenti e attivati
   *  - 0: prodotti non presenti (quindi non necessaria attivazione)
   *  - -1: errore nell'attivazione
   */
  async activateProduct(): Promise<number> {
    return await this.orderDetailService.activatePreviouslyAssignedProducts(this.currentUser.accountId)
  }

  updateSingleItems(user: IAuthenticateResponse) {
    user.jwtToken = this.currentUser.jwtToken
    user.refreshToken = this.currentUser.refreshToken
    user.preferredContactHours = `${this.fromHour}-${this.toHour}`
    this.auth.setCurrentUser(user)
  }

  async loginWithDiscord() {
    this.auth.loginWithDiscord()
      .then((res: DiscordLoginResponse) => {
        window.location.href = res.url
      })
      .catch(() => {

      })
  }

  /**
   * controlla se l'utente deve aggiungere un account discord
   * (se non ha uno username che rispetta il formato discord)
   */
  checkShouldAddDiscordAccount() {
    const regex = new RegExp(DiscordPattern)
    if (!regex.test(this.currentUser?.username)) {
      this.auth.shouldAddDiscordAccount.next(true)
    }
  }
}
