<div [@fade]="product"
     class="mx-auto relative bg-grayBgDark w-full rounded-2xl overflow-hidden transition-std border-[1px] border-gray-700 hover:border-gray-500">
  <a
    [routerLink]="['/dashboard', 'my-product', product.productId, product.name.split(' ').join('-').toLowerCase(), product.productEnvironment]"
    class="block relative w-full h-44 sm:h-72 sm:w-72 rounded-2xl overflow-hidden"
    routerLinkActive="router-link-active">
    <!-- COMING SOON -->
    <div *ngIf="product.productAvailability === productAvailability.ComingSoon"
         class="flex absolute top-0 left-0 z-10 w-full h-full bg-black/75">
      <p class="m-auto text-white font-semibold text-md md:text-xl">Coming soon...</p>
    </div>
    <!-- END COMING SOON -->
    <img [src]="product.imageUrl != '' ? serverUrl + product.imageUrl : defaultImage"
         alt="{{ product.name }} by Top Trading Academy"
         class="absolute object-cover object-center rounded-2xl w-full h-full block"
         title="{{ product.name }} by Top Trading Academy">
  </a>
  <div class="py-4 sm:py-6">
    <h2 class="text-gray-400 text-xs tracking-widest title-font mb-1">
      {{ productType[product.productType] | replaceAll: "_": " " }}
    </h2>
    <h3
      class="text-white title-font max-w-[18rem] text-md sm:text-lg font-medium text-ellipsis overflow-hidden">
      {{ product.name }}
    </h3>
  </div>
</div>
