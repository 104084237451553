<div
  class="flex flex-col justify-center min-h-screen w-full h-full pt-4 px-4 text-center sm:block sm:p-0 bg-customDark">
  <div *ngIf="myProduct != undefined" [@fade]="myProduct" class="m-auto h-screen">
    <div class="flex flex-col m-auto w-full h-full">

      <div class="flex flex-col m-auto w-full">
        <!--Sezione abbonamenti-->
        <ng-container>
<!--           <div
            *ngIf="myProduct && isProductSubscribable && !isAlreadySubscribed && (!this.subscribableProductTypes.includes(myProduct.productType) || (myProduct.productType === productType.Indicatore && myProduct.typeCovers !== typeCovers.Cover))"
            class="flex flex-col m-auto my-4 xs:mt-12">
            <div>
              <button (click)="toggleSubscriptionModal()" class="button-small">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor"
                     class="inline-flex my-auto h-4 w-4 mr-2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"/>
                </svg>
                Abbonati
              </button>
            </div>
            <p *ngIf="myProduct.productType !== productType.Membership" class="text-gray-400 text-sm pt-2">
              Clicca per abbonarti all'assistenza {{myProduct.name}}
            </p>
            <p *ngIf="myProduct.productType === productType.Membership" class="text-gray-400 text-sm pt-2">
              Clicca per abbonarti a {{myProduct.name}}
            </p>
          </div> -->
          <!-- <div class="flex flex-col m-auto my-4" *ngIf="isAlreadySubscribed">
            <div
              class="flex flex-row mx-auto py-2  px-8 space-x-2 text-xs bg-green-500/25 text-green-500 rounded-full">
              <svg class="m-auto" width="20" height="20" fill="currentColor"
                   viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.75 3.118A2.344 2.344 0 0 0 19.412.797L4.577.75A2.327 2.327 0 0 0 2.25 3.071v15.304c0 1.28 1.044 2.25 2.327 2.25H17.25l-.61-2.063 5.11 4.688V3.118ZM15.218 15.46s-.409-.487-.75-.906c1.488-.419 2.055-1.335 2.055-1.335a6.517 6.517 0 0 1-1.305.668c-.53.22-1.082.383-1.646.487a7.973 7.973 0 0 1-2.94-.011 8.65 8.65 0 0 1-1.668-.488 6.647 6.647 0 0 1-.829-.385c-.034-.022-.068-.034-.102-.057-.023-.01-.034-.022-.047-.022-.204-.114-.318-.193-.318-.193s.545.895 1.987 1.325c-.34.43-.76.929-.76.929-2.51-.08-3.463-1.71-3.463-1.71 0-3.612 1.635-6.545 1.635-6.545C8.7 6.006 10.245 6.04 10.245 6.04l.113.136c-2.043.577-2.973 1.472-2.973 1.472s.25-.136.67-.318c1.214-.532 2.179-.668 2.577-.713.064-.013.128-.02.193-.023a9.64 9.64 0 0 1 2.293-.022 9.451 9.451 0 0 1 3.417 1.076s-.897-.851-2.827-1.428l.159-.18s1.555-.035 3.179 1.179c0 0 1.634 2.932 1.634 6.544 0-.013-.954 1.618-3.462 1.697Z"></path>
                <path
                  d="M9.94 10.219c-.647 0-1.158.555-1.158 1.245 0 .69.522 1.246 1.158 1.246.647 0 1.158-.555 1.158-1.246.012-.692-.511-1.245-1.158-1.245Z"></path>
                <path
                  d="M14.083 10.219c-.647 0-1.158.555-1.158 1.245 0 .69.522 1.246 1.158 1.246.647 0 1.157-.555 1.157-1.246 0-.69-.521-1.245-1.157-1.245Z"></path>
              </svg>
              <span class="my-auto">Abbonamento Attivo</span>
            </div>
            <p *ngIf="myProduct.productType !== productType.Membership" class="text-gray-400 text-sm pt-2">
              Sei abbonato all'assistenza {{myProduct.name}}!
            </p>
            <p *ngIf="myProduct.productType === productType.Membership" class="text-gray-400 text-sm pt-2">
              Sei abbonato a {{myProduct.name}}!
            </p>
          </div> -->
        </ng-container>
        <h1
          class="title-main mt-0">
          {{ myProduct.name }}
        </h1>
        <h2 class="text-base pb-6 title-font text-gray-200 lg:text-gray-400 tracking-widest">
          {{ productType[myProduct.productType] | replaceAll: "_": " " }}
        </h2>
        <img *ngIf="myProduct != undefined"
             [src]="myProduct.imageUrl != '' && myProduct.imageUrl != undefined ? serverUrl + myProduct.imageUrl : defaultImage"
             alt="{{ myProduct.name }} by Top Trading Academy"
             class="mx-auto w-1/3 md:w-1/4 lg:w-1/6 object-cover border border-gray-700 rounded-2xl object-center"
             title="{{ myProduct.name }} by Top-Trading Academy">
        <p class="md:w-2/3 lg:w-1/3 mx-auto text-base pt-8 text-gray-400">
          {{ myProduct.description }}
        </p>
        <!-- SCORRI -->
        <div *ngIf="myProduct.childs.length > 0" [@fade]="myProduct" class="flex flex-col mt-4">
          <h3 class="flex mx-auto text-lg xs:text-xl title-font text-white tracking-wide">
            Scorri per i contenuti
          </h3>
          <div class="flex mx-auto text-center mt-2 md:mt-4">
            <svg class="inline-flex my-auto animate-bounce h-5 w-5 xs:h-6 xs:w-6" fill="none"
                 stroke="#D97706" viewBox="0 0 24 24">
              <path d="M19 14l-7 7m0 0l-7-7m7 7V3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- CHILDS OPTIONAL -->
<div *ngIf="!childLoading && myProduct != undefined && myProduct.childs != undefined && (showProductChildrenOptional.length > 0 || assistenzaProductOptional.length > 0) "
  class="flex flex-col h-full bg-customDark">
  <div class="py-12 sm:py-24 text-center flex flex-col m-auto space-y-2">
    <h1 class="m-auto text-2xl md:text-3xl font-medium text-white underline decoration-redCustom underline-offset-2">
      Contenuti acquistati
    </h1>
    <p class="m-auto text-gray-400">
      Lista di contenuti acquistati per <span class="font-bold">{{myProduct.name}}</span>
    </p>
  </div>

  <app-loading-animation *ngIf="childLoading"></app-loading-animation>

  <div *ngIf="assistenzaProductOptional.length > 0" class="font-medium text-gray-300 mx-auto">
    <div *ngFor="let item of assistenzaProductOptional" class="flex flex-col justify-between items-center">
      <div class="flex">
        <span class="text-xl font-bold mr-4 inline-flex">{{item.name}}</span>
        <div
          class="flex flex-row mx-auto py-2 px-8 space-x-2 text-xs bg-green-500/25 text-green-500 rounded-full">
          <svg class="m-auto" width="20" height="20" fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.75 3.118A2.344 2.344 0 0 0 19.412.797L4.577.75A2.327 2.327 0 0 0 2.25 3.071v15.304c0 1.28 1.044 2.25 2.327 2.25H17.25l-.61-2.063 5.11 4.688V3.118ZM15.218 15.46s-.409-.487-.75-.906c1.488-.419 2.055-1.335 2.055-1.335a6.517 6.517 0 0 1-1.305.668c-.53.22-1.082.383-1.646.487a7.973 7.973 0 0 1-2.94-.011 8.65 8.65 0 0 1-1.668-.488 6.647 6.647 0 0 1-.829-.385c-.034-.022-.068-.034-.102-.057-.023-.01-.034-.022-.047-.022-.204-.114-.318-.193-.318-.193s.545.895 1.987 1.325c-.34.43-.76.929-.76.929-2.51-.08-3.463-1.71-3.463-1.71 0-3.612 1.635-6.545 1.635-6.545C8.7 6.006 10.245 6.04 10.245 6.04l.113.136c-2.043.577-2.973 1.472-2.973 1.472s.25-.136.67-.318c1.214-.532 2.179-.668 2.577-.713.064-.013.128-.02.193-.023a9.64 9.64 0 0 1 2.293-.022 9.451 9.451 0 0 1 3.417 1.076s-.897-.851-2.827-1.428l.159-.18s1.555-.035 3.179 1.179c0 0 1.634 2.932 1.634 6.544 0-.013-.954 1.618-3.462 1.697Z"></path>
              <path
                d="M9.94 10.219c-.647 0-1.158.555-1.158 1.245 0 .69.522 1.246 1.158 1.246.647 0 1.158-.555 1.158-1.246.012-.692-.511-1.245-1.158-1.245Z"></path>
              <path
                d="M14.083 10.219c-.647 0-1.158.555-1.158 1.245 0 .69.522 1.246 1.158 1.246.647 0 1.157-.555 1.157-1.246 0-.69-.521-1.245-1.157-1.245Z"></path>
          </svg>
          <span class="my-auto">Abbonamento Attivo</span>
        </div>
      </div>
      
      <div class=" py-2">
          <span (click)="navigateAndReload(item.productId, item.name, item.productEnvironment)"
          class="text-lg mr-4 hover:text-redCustom">Clicca qui per visualizzare i contenuti dell'abbonamento</span> 
      </div>
    </div>
  </div>

  <!-- SEZIONI -->
  <div *ngFor="let section of productSectionsOptional; let j = index" class="flex flex-col w-full md:w-2/3 mx-auto">
    <h1
      class="py-4 sm:pt-12 m-auto text-4xl font-medium text-focus-in text-white underline decoration-redCustom underline-offset-2">
      {{ section | replaceAll:'_':' ' | titleCaseCustom }}
    </h1>
    <p class="m-auto text-focus-in text-gray-400 pb-4"
       *ngIf="myProduct.productType !== productType.Pacchetto_Indicatori && myProduct.productType
       && !(myProduct.productType === productType.Indicatore && myProduct.typeCovers === typeCovers.Cover)">
      Video Sezione {{ section | replaceAll:'_':' ' | titleCaseCustom }}
    </p>

    <table class="mx-auto w-full divide-y divide-gray-600">
      <thead class="bg-customDark text-left text-sm md:text-md uppercase tracking-wider">
      <tr class="space-x-2 md:space-x-5 xl:space-x-8 font-medium text-gray-300">
        <th class="pl-2 md:pl-3 py-3 text-left" scope="col">ID</th>
        <th class="py-3 text-left" scope="col">Nome</th>
        <th *ngIf="myProduct.productType === productType.Corso"
            class="py-3 text-center font-medium text-gray-300 uppercase tracking-wider"
            scope="col">Difficoltà
        </th>
        <th class="py-3 text-center" scope="col">Azioni</th>
        <th
          *ngIf="myProduct.productType !== productType.Pacchetto_Indicatori && !(myProduct.productType === productType.Indicatore && myProduct.typeCovers === typeCovers.Cover)"
          class="py-3 pr-2 text-center" scope="col">
          Visto
        </th>
      </tr>
      </thead>
      <!-- VIDEO CON SEZIONE -->
      <tbody class="bg-customDark divide-y divide-gray-700 text-gray-300">
      <tr
        *ngFor="let item of filteredProductChildrenOptional | filterSection: section | paginate: { itemsPerPage: 10, currentPage: currentPage[j], id: section }; let i = index"
        [attr.data-index]="i" class="space-x-2 md:space-x-5 xl:space-x-8">
        <td class="pl-2 md:pl-3 py-4 whitespace-nowrap">
          {{ currentPage[j] ? currentPage[j] == 1 ? i + 1 : currentPage[j] * 10 - 10 + i + 1 : i + 1 }}
        </td>
        <td class="font-medium text-sm lg:text-base">{{ item.name }}</td>
        <td *ngIf="myProduct.productType === productType.Corso" class="text-center py-4 whitespace-nowrap font-medium">
          <div class="inline-flex flex-row m-auto mt-1 text-center space-x-1">
            <div class="inline-flex flex-row m-auto text-center space-x-1">
              <span class="inline-flex my-auto">
                {{ item.productRanking }}
              </span>
              <svg class="inline-flex my-auto h-5 w-5" fill="currentColor" viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"/>
              </svg>
            </div>
          </div>
        </td>
        <td class="text-center pr-2">
          <span
            [routerLink]="['/dashboard', 'my-product', myProduct.productId, myProduct.name.split(' ').join('-').toLowerCase(), item.productId, item.name.split(' ').join('-').toLowerCase()]"
            class="py-1 px-3 inline-flex my-auto flex-row text-xs md:text-sm leading-5 font-semibold rounded-full bg-blue-700 text-white shadow-dark transition-std hover:bg-blue-900 cursor-pointer"
            routerLinkActive="router-link-active">
            <svg class="mr-2 inline-flex my-auto h-4 w-4" fill="none" stroke="currentColor"
                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2"/>
            </svg>
            {{ item.productType === productType.Indicatore ? 'Istruzioni' : 'Guarda'}}
          </span>
          <a *ngIf="item.productType === productType.Indicatore" [href]="item.note"
             class="ml-2 py-1 px-3 inline-flex my-auto flex-row text-sm leading-5 font-semibold rounded-full bg-red-700 text-white shadow-dark transition duration-300 hover:bg-red-900 cursor-pointer"
             download
             rel="noreferrer"
             target="_blank">
            <svg class="mr-2 inline-flex my-auto h-4 w-4" fill="none" stroke="currentColor"
                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2"/>
            </svg>
            Download
          </a>
        </td>
        <!--VISTO-->
        <td class="text-center"
            *ngIf="myProduct.productType !== productType.Pacchetto_Indicatori && !(myProduct.productType === productType.Indicatore && myProduct.typeCovers === typeCovers.Cover)">
          <input
            (change)="markVideoAsSeen(item.productId, $event)"
            [checked]="lastSeenVideos | hasBeenSeen: item"
            class="checkbox-blue"
            type="checkbox">
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="!childLoading && myProduct != undefined && myProduct.childs != undefined && showProductChildren.length > 0"
     class="flex flex-col h-full bg-customDark">
  <div class="py-12 sm:py-24 text-center flex flex-col m-auto space-y-2">
    <h1 class="m-auto text-2xl md:text-3xl font-medium text-white underline decoration-redCustom underline-offset-2">
      Contenuti offerti
    </h1>
    <p class="m-auto text-gray-400">
      Lista di contenuti offerti per <span class="font-bold">{{myProduct.name}}</span>
    </p>
  </div>
  <app-loading-animation *ngIf="childLoading"></app-loading-animation>
  <!-- SEZIONI -->
  <div *ngFor="let section of productSections; let j = index" class="flex flex-col w-full md:w-2/3 mx-auto">
    <h1
      class="py-4 sm:pt-12 m-auto text-4xl font-medium text-focus-in text-white underline decoration-redCustom underline-offset-2">
      {{ section | replaceAll:'_':' ' | titleCaseCustom }}
    </h1>
    <p class="m-auto text-focus-in text-gray-400 pb-4"
       *ngIf="myProduct.productType !== productType.Pacchetto_Indicatori && myProduct.productType
       && !(myProduct.productType === productType.Indicatore && myProduct.typeCovers === typeCovers.Cover)">
      Video Sezione {{ section | replaceAll:'_':' ' | titleCaseCustom }}
    </p>

    <table class="mx-auto w-full divide-y divide-gray-600">
      <thead class="bg-customDark text-left text-sm md:text-md uppercase tracking-wider">
      <tr class="space-x-2 md:space-x-5 xl:space-x-8 font-medium text-gray-300">
        <th class="pl-2 md:pl-3 py-3 text-left" scope="col">ID</th>
        <th class="py-3 text-left" scope="col">Nome</th>
        <th *ngIf="myProduct.productType === productType.Corso"
            class="py-3 text-center font-medium text-gray-300 uppercase tracking-wider"
            scope="col">Difficoltà
        </th>
        <th class="py-3 text-center" scope="col">Azioni</th>
        <th
          *ngIf="myProduct.productType !== productType.Pacchetto_Indicatori && !(myProduct.productType === productType.Indicatore && myProduct.typeCovers === typeCovers.Cover)"
          class="py-3 pr-2 text-center" scope="col">
          Visto
        </th>
        <th *ngIf="myProduct.productType == productType.Pacchetto_Indicatori"
          class="py-3 text-center" scope="col">Codice attivazione</th>
      </tr>
      </thead>
      <!-- VIDEO CON SEZIONE -->
      <tbody class="bg-customDark divide-y divide-gray-700 text-gray-300">
      <tr
        *ngFor="let item of filteredProductChildren | filterSection: section | paginate: { itemsPerPage: 10, currentPage: currentPage[j], id: section }; let i = index"
        [attr.data-index]="i" class="space-x-2 md:space-x-5 xl:space-x-8">
        <td class="pl-2 md:pl-3 py-4 whitespace-nowrap">
          {{ currentPage[j] ? currentPage[j] == 1 ? i + 1 : currentPage[j] * 10 - 10 + i + 1 : i + 1 }}
        </td>
        <td class="font-medium text-sm lg:text-base">{{ item.name }}</td>
        <td *ngIf="myProduct.productType === productType.Corso" class="text-center py-4 whitespace-nowrap font-medium">
          <div class="inline-flex flex-row m-auto mt-1 text-center space-x-1">
            <div class="inline-flex flex-row m-auto text-center space-x-1">
              <span class="inline-flex my-auto">
                {{ item.productRanking }}
              </span>
              <svg class="inline-flex my-auto h-5 w-5" fill="currentColor" viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"/>
              </svg>
            </div>
          </div>
        </td>
        <td class="text-center pr-2">
          <span
            [routerLink]="['/dashboard', 'my-product', myProduct.productId, myProduct.name.split(' ').join('-').toLowerCase(), item.productId, item.name.split(' ').join('-').toLowerCase()]"
            class="py-1 px-3 inline-flex my-auto flex-row text-xs md:text-sm leading-5 font-semibold rounded-full bg-blue-700 text-white shadow-dark transition-std hover:bg-blue-900 cursor-pointer"
            routerLinkActive="router-link-active">
            <svg class="mr-2 inline-flex my-auto h-4 w-4" fill="none" stroke="currentColor"
                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2"/>
            </svg>
            {{ item.productType === productType.Indicatore ? 'Istruzioni' : 'Guarda'}}
          </span>
          <a *ngIf="item.productType === productType.Indicatore" [href]="item.note"
             class="ml-2 py-1 px-3 inline-flex my-auto flex-row text-sm leading-5 font-semibold rounded-full bg-red-700 text-white shadow-dark transition duration-300 hover:bg-red-900 cursor-pointer"
             download
             rel="noreferrer"
             target="_blank">
            <svg class="mr-2 inline-flex my-auto h-4 w-4" fill="none" stroke="currentColor"
                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2"/>
            </svg>
            Download
          </a>
        </td>
        <!--VISTO-->
        <td class="text-center"
            *ngIf="myProduct.productType !== productType.Pacchetto_Indicatori && !(myProduct.productType === productType.Indicatore && myProduct.typeCovers === typeCovers.Cover)">
          <input
            (change)="markVideoAsSeen(item.productId, $event)"
            [checked]="lastSeenVideos | hasBeenSeen: item"
            class="checkbox-blue"
            type="checkbox">
        </td>
        <td class="font-medium text-sm lg:text-base"
          *ngIf="myProduct.productType == productType.Pacchetto_Indicatori"> {{ item.activationCode }} </td>
      </tr>
      </tbody>
    </table>
    <div class="w-full py-8 flex m-auto">
      <pagination-controls
        (pageChange)="pageChange($event, j, section)"
        [autoHide]="true" [id]="section" [maxSize]="5"
        class="m-auto my-pagination font-urbanist font-semibold tracking-wide text-gray-500"
        nextLabel="" previousLabel="">
      </pagination-controls>
    </div>
  </div>
</div>

<app-subscription-modal
  (isAlreadySubscribed)="toggleAlreadySubscribed($event)">
</app-subscription-modal>