<div *ngIf="showModal" [@fade] aria-labelledby="modal-title"
     aria-modal="true" class="fixed z-10 inset-0 overflow-y-auto text-focus-in-fast" role="dialog">
    <div
            class="transition-std flex items-end justify-center min-h-screen w-full text-center sm:block sm:p-0">
        <div (click)="toggleModal($event)" aria-hidden="true"
             class="fixed inset-0 bg-customDark opacity-70 transition-opacity"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

        <div
                class="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-lg sm:w-2/3 -translate-y-24 md:translate-y-0">
            <svg (click)="toggleModal($event)"
                 class="text-white absolute top-5 right-5 h-6 w-6 cursor-pointer hover:text-redCustom transition-std"
                 fill="none"
                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </svg>

            <div class="flex bg-customDark border rounded-lg overflow-visible border-gray-700 w-full">
                <div class="mx-auto">
                    <div class="mt-10 sm:mt-0">
                        <div class="flex w-full">
                            <div class="mt-5 md:mt-0 md:col-span-6">
                                <div class="overflow-hidden sm:rounded-md">
                                    <div class="w-full">
                                        <div class="flex flex-col mx-auto text-left text-white space-y-2 flex-nowrap">
                                            <div class="relative flex w-full min-h-full">
                                                <app-video-player
                                                        [dimensions]="['576','324']"
                                                        [facadeId]="videoTitle"
                                                        [label]="videoTitle"
                                                        [vimeoLink]="videoUrl">
                                                </app-video-player>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
