<div class="relative font-urbanist text-gray-300 flex bg-green-blob-dark bg-cover bg-no-repeat align-middle h-screen">
  <div class="flex flex-col m-auto w-full text-center">
    <h1 class="mx-auto text-3xl font-bold text-white">Pagamento avvenuto con successo!</h1>
    <h2 *ngIf="loading" class="mx-auto text-lg text-center mt-1 text-white">
      Stiamo processando il tuo ordine, riceverai una conferma tra qualche instante.
    </h2>
    <h2 *ngIf="!loading" class="mx-auto text-lg text-center mt-1 text-white">
      Il tuo Abbonamento è stato attivato correttamente, vai nella sezione I Miei Prodotti.
    </h2>
    <div class="flex flex-col m-auto mb-4">
      <!-- CARD PRODOTTO -->
      <div *ngIf="product"
           class="hidden sm:block mx-auto relative mt-4 md:mt-8 bg-grayBgDark rounded-lg text-focus-in-fast hover:scale-105 shadow-dark transition duration-300 ease-in-out border-[1px] border-gray-700 hover:border-gray-600">
        <div class="block relative h-72 w-72 rounded overflow-hidden">
          <img alt="{{ product.name }} by Top Trading Academy" title="{{ product.name }} by Top Trading Academy"
               class="absolute object-cover object-center w-full h-full block rounded-2xl"
               [src]="serverUrl + product.imageUrl">
        </div>
        <div class="py-6">
          <h2
            class="text-gray-400 text-xs tracking-widest title-font mb-1">{{ productType[product.productType] | replaceAll: "_": " " }}
          </h2>
          <h3 class="text-white title-font text-lg font-medium">{{ product.name }}</h3>
          <p class="text-green-400">
            Abbonato
            <svg xmlns="http://www.w3.org/2000/svg" class="inline-flex h-4 w-4 m-auto" viewBox="0 0 20 20"
                 fill="currentColor">
              <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"/>
            </svg>
          </p>
        </div>
      </div>
    </div>
    <p *ngIf="loading">
      <span class="text-redCustom font-semibold">Attenzione</span>: Non lasciare questa pagina mentre il processo è in
      corso.
    </p>
    <div *ngIf="loading" class="flex flex-row mx-auto mt-4">
      <img class="animate-bounce" src="../../../assets/images/tt_logo.png" alt="TT Logo" width="50" height="auto">
      <p class="text-lg">Carico...</p>
    </div>
  </div>
</div>